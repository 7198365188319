import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { H2, PMedium } from 'src/styles/commons'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { FaqQuestions } from './FaqQuestions/FaqQuestions'

export const Faq = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)

  const handleBackButton = () => {
    if (accessViaDeepLink) {
      dispatch(PathParamsActions.setAccessedViaDeepLink(false))
    }

    // TODO: Ajustar lógica pra retornar pra tela de simulador caso FAQ seja chamada a partir de lá
    history.push(TypesRoutes.START)
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIUM,
    hideFaqBtn: true,
    backNavigationHandler: handleBackButton,
  })

  return (
    <InterUIContainer margin="0">
      <InterUIContainer margin="24px">
        <H2 marginBottom="8px">Afinal, o que é um consórcio?</H2>
        <PMedium marginBottom="40px" scale={400}>
          O consórcio é um modelo de compra, que reúne pessoas interessadas em adquirir um bem ou
          serviço.
        </PMedium>

        <FaqQuestions />
      </InterUIContainer>

      <Babi />
    </InterUIContainer>
  )
}
