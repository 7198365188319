import BaseBridge from 'src/config/bridge/BaseBridge'

import { InterUIBabi, InterUIContainer } from '@interco/inter-ui-react-lib'

interface BabiProps {
  margin?: string
}

export const Babi = ({ margin = '24px' }: BabiProps) => (
  <InterUIContainer margin={margin}>
    <InterUIBabi onClick={BaseBridge.requestBabi} />
  </InterUIContainer>
)
