import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { LoadCards } from '../components/LoadCards/LoadCards'
import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export const LoadLettersCredit = () => (
  <SkeletonContent data-testid="loading-letters-credit">
    <InterUIContainer margin="24px 0">
      <SkeletonGrid>
        <LoadCards qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadCards qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadCards qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadCards qtd={2} />
      </SkeletonGrid>
    </InterUIContainer>
  </SkeletonContent>
)
