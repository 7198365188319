import { action } from 'typesafe-actions'

import { IEligibleRequest, ValidationTypes } from './types'

const getEligibleRequest = (data?: IEligibleRequest) =>
  action(ValidationTypes.GET_ELIGIBLE_CALL_REQUEST, data)

export const ValidationActions = {
  getEligibleRequest,
}
