import { Reducer } from 'redux'
import { formPaymentEmpty } from 'src/mocks/Checkout'

import { CheckoutState, CheckoutTypes, IFormOfPayment } from './types'

const INITIAL_STATE: CheckoutState = {
  formOfPayment: {
    title: formPaymentEmpty.title,
    subTitle: formPaymentEmpty.subTitle,
    type: formPaymentEmpty.type,
  } as IFormOfPayment,
}

const reducer: Reducer<CheckoutState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CheckoutTypes.SET_FORM_OF_PAYMENT:
      return { ...state, formOfPayment: action.payload }
    default:
      return state
  }
}

export default reducer
