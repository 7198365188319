import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import ValidationService from 'src/services/ValidationService'
import { errorHandlingSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, takeLatest } from 'typed-redux-saga'

import { ErrorActions } from '../error/actions'
import { ErrorDetails } from '../error/types'
import { LoadingActions } from '../loading/actions'
import { IEligibleRequest, ValidationTypes } from './types'

function* getEligible({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { onTransmission } = (payload || {}) as IEligibleRequest

    BaseBridge.requestAnalytics('C_FRONT_ISCON_ELIGIBILITY_BEFORE', {
      file: 'validation/saga',
      action: 'Antes de chamar ValidationService.getEligible',
    })

    const response = yield* call(ValidationService.getEligible)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_ELIGIBILITY_AFTER', {
      file: 'validation/saga',
      action: 'Response do ValidationService.getEligible',
      response: JSON.stringify(response.object),
    })

    const eligibility = response.object

    yield* put(LoadingActions.hide())

    if (eligibility.eligible) {
      onTransmission(eligibility.eligible)
    } else {
      const errorDetails: ErrorDetails = {
        title: eligibility.title || 'Houve um erro por aqui',
        subTitle:
          eligibility.message ||
          'No momento, a aplicação está indisponível. Por favor, tente novamente em alguns minutos.',
        route: TypesRoutes.REQUEST_GO_BACK,
      }
      yield* put(ErrorActions.show(errorDetails))
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ValidationActions.getEligible', undefined, true)
  }
}

export default function* watchValidation() {
  yield* takeLatest(ValidationTypes.GET_ELIGIBLE_CALL_REQUEST, getEligible)
}
