import { Reducer } from 'redux'

import { ErrorDetails, ErrorState, ErrorTypes } from './types'

const INITIAL_STATE: ErrorState = {
  toggle: false,
  details: {} as ErrorDetails,
}

const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.SHOW:
      return { ...state, toggle: true, details: action.payload }

    case ErrorTypes.HIDE:
      return { ...state, toggle: false }

    case ErrorTypes.SET_ERROR_DETAILS:
      return { ...state, details: action.payload }

    default:
      return state
  }
}

export default reducer
