import { InterUIContainer, InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export const LoadReceipt = () => (
  <SkeletonContent data-testid="loading-receipt">
    <InterUIContainer margin="24px">
      <SkeletonContent display="flex" flexDirection="column" alignItems="center">
        <InterUISkeleton variant="circle" height="64px" width="64px" marginBottom="24px" />
        <InterUISkeleton height="32px" width="100%" marginBottom="8px" />
        <InterUISkeleton height="32px" width="100%" marginBottom="0px" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUISeparator height="8px" margin="24px 0" />

    <InterUIContainer margin="24px">
      <SkeletonContent>
        <InterUISkeleton height="24px" width="60%" marginBottom="16px" />
        <InterUISkeleton height="16px" width="30%" marginBottom="4px" />
        <InterUISkeleton height="16px" width="20%" marginBottom="16px" />

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="45%" marginBottom="4px" />
          <InterUISkeleton height="16px" width="40%" marginBottom="4px" />
        </SkeletonGrid>

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="30%" marginBottom="16px" />
          <InterUISkeleton height="16px" width="50%" marginBottom="16px" />
        </SkeletonGrid>

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="35%" marginBottom="4px" />
          <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
        </SkeletonGrid>

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="30%" marginBottom="16px" />
          <InterUISkeleton height="16px" width="10%" marginBottom="16px" />
        </SkeletonGrid>

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
          <InterUISkeleton height="16px" width="30%" marginBottom="4px" />
        </SkeletonGrid>

        <SkeletonGrid>
          <InterUISkeleton height="16px" width="30%" marginBottom="16px" />
          <InterUISkeleton height="16px" width="10%" marginBottom="16px" />
        </SkeletonGrid>

        <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
        <InterUISkeleton height="16px" width="40%" marginBottom="16px" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUISeparator height="8px" margin="24px 0" />
  </SkeletonContent>
)
