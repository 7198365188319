export const TAGS = {
  C_CONSOR_HOME_DC_ACESSO: {
    ref_figma: '1',
    ref_type: 'tela',
    screen: 'C_CONSOR_HOME_DC_ACESSO',
    content_action: 'Dado carregado',
    action_id: 'Acessou a home de consórcios',
  },
  C_CONSOR_COMPROVANTE_DC_ACESSO: {
    ref_figma: '9',
    ref_type: 'tela',
    screen: 'C_CONSOR_COMPROVANTE_DC_ACESSO',
    content_action: 'Dado carregado',
    Modalidade: 'tipo de consórcios',
    action_id: 'Acessou a tela comprovante',
  },
  CONSOR_FLUXO: {
    ref_type: 'botão',
    screen: 'CONSOR_FLUXO',
    content_action: 'clique',
    action_id: 'Acessou a home de consórcios',
  },
  C_CONSOR_RESUMO_DC_ACESSO: {
    ref_figma: '6',
    ref_type: 'tela',
    screen: 'C_CONSOR_RESUMO_DC_ACESSO',
    content_action: 'Dado carregado',
    Modalidade: 'tipo de consórcios',
  },
  C_CONSOR_BANNER_DISPLAY: {
    ref_figma: '0',
    ref_type: 'tela',
    screen: 'C_CONSOR_HOME',
    section: 'Destaques modalidades',
    content_action: 'Display',
    product: 'Inter Seguros Consórcio',
  },
  C_CONSORCIO_RESET_SESSION: 'C_CONSORCIO_RESET_SESSION',
}
