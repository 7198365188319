import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FilterCardEnum } from 'src/enums/FilterCard'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { IModality } from 'src/store/ducks/hiring/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TabActions } from 'src/store/ducks/tab/actions'

import { TypesRoutes } from './mixedRoutes/types'

enum deepLinkPagesEnum {
  FAQ = 'informativo',
  SIMULATOR = 'subproduto',
  CARDS = 'cartas',
}

enum deepLinkFilterEnum {
  MIN = 'menorpreco',
  MAX = 'maiorpreco',
  HIGHLIGHTS = 'destaque',
}

export interface IDeepLinkParams {
  page: string
  productid?: string
  subproductid?: string
  vparcela?: string
  vconsorcio?: string
  filtro?: string
  isVip: boolean
}

interface IDeepLinkDictionary {
  [key: string]: string
}

export function DeepLinkHandler() {
  const history = useHistory()
  const dispatch = useDispatch()

  const deepLinkDictionary = {} as IDeepLinkDictionary

  const handler = (params: string) => {
    processParams(params)
  }

  const processParams = (params: string) => {
    const onlyParams = `page${params.split('params')[1]}`
    const paramsArray = onlyParams.split('&')

    paramsArray.forEach((param) => {
      const keyValue = param.split('=')
      deepLinkDictionary[keyValue[0]] = keyValue[1].toString()
    })

    processRoute()
  }

  const isDeepLinkValid = (deepLinkParams: IDeepLinkParams) => {
    if (deepLinkParams.page === deepLinkPagesEnum.FAQ) return true

    if (deepLinkParams.page === deepLinkPagesEnum.SIMULATOR && deepLinkParams.subproductid)
      return true

    if (deepLinkParams.page === deepLinkPagesEnum.CARDS && deepLinkParams.productid) return true

    if (deepLinkParams.page === deepLinkPagesEnum.CARDS && deepLinkParams.isVip) return true

    return false
  }

  const processRoute = () => {
    dispatch(PathParamsActions.setAccessedViaDeepLink(true))
    const deepLinkParams = deepLinkDictionary as unknown as IDeepLinkParams

    if (!isDeepLinkValid(deepLinkParams)) {
      history.replace(TypesRoutes.START)
      return
    }

    switch (deepLinkDictionary.page) {
      case deepLinkPagesEnum.FAQ:
        history.push(TypesRoutes.FAQ)
        break
      case deepLinkPagesEnum.SIMULATOR:
        handleSimulator(deepLinkParams)
        break
      case deepLinkPagesEnum.CARDS:
        handleCards(deepLinkParams)
        break
      default:
        history.replace(TypesRoutes.START)
        break
    }
  }

  const handleSimulator = (deepLinkParams: IDeepLinkParams) => {
    const { subproductid } = deepLinkParams

    if (subproductid) {
      dispatch(
        HiringActions.getSubproductRequest({
          history,
          pathname: TypesRoutes.PRE_SIMULATE,
          subProductId: subproductid,
        }),
      )
    }
  }

  const handleCards = (deepLinkParams: IDeepLinkParams) => {
    const { productid, subproductid, vparcela, vconsorcio, filtro, isVip } = deepLinkParams

    vconsorcio ? dispatch(TabActions.amount()) : dispatch(TabActions.installment())

    const modality = {
      productId: productid,
      subproductId: subproductid,
      isVip,
    } as unknown as IModality
    dispatch(HiringActions.setSelectedModality(modality))
    switch (filtro) {
      case deepLinkFilterEnum.MIN:
        dispatch(PathParamsActions.setDeepLinkCardsFilter(FilterCardEnum.MIN))
        break
      case deepLinkFilterEnum.MAX:
        dispatch(PathParamsActions.setDeepLinkCardsFilter(FilterCardEnum.MAX))
        break
      case deepLinkFilterEnum.HIGHLIGHTS:
        dispatch(PathParamsActions.setDeepLinkCardsFilter(FilterCardEnum.HIGHLIGHTS))
        break
      default:
        break
    }

    dispatch(
      HiringActions.getCardsRequest({
        history,
        pathname: TypesRoutes.LETTERS_CREDIT,
        productId: productid ? Number(productid) : undefined,
        subProductId: subproductid ? Number(subproductid) : undefined,
        installmentAmount: vparcela ? Number(vparcela) : undefined,
        totalAmount: vconsorcio ? Number(vconsorcio) : undefined,
        lowestPrice: filtro === deepLinkFilterEnum.MIN,
        biggestPrice: filtro === deepLinkFilterEnum.MAX,
        highlights: filtro === deepLinkFilterEnum.HIGHLIGHTS,
        isVip: isVip ? Boolean(isVip) : undefined,
      }),
    )
  }

  return handler
}
