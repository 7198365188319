import { Fragment } from 'react'

import { useSelector } from 'react-redux'
import { IReceiptStatus } from 'src/Interfaces/IRequest'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { ErrorSign, H1, PMedium, SuccessSign } from 'src/styles/commons'
import { SIZE_LG } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface IReceiptStatusProps {
  status: IReceiptStatus
}

export const ReceiptStatus = ({ status }: IReceiptStatusProps) => {
  const theme = useTheme()

  const { sectionHeader } = useSelector(HiringActions.getReceipt)

  const { white } = theme.colors.base

  const statusIcon = {
    successIcon: <Icons.CheckCircle width={SIZE_LG} height={SIZE_LG} color={white} />,
    canceledIcon: <Icons.Canceled width={SIZE_LG} height={SIZE_LG} color={white} />,
  }

  const renderAnalysis = (
    <>
      <SuccessSign>{statusIcon.successIcon}</SuccessSign>
      <H1 margin="40px 0 8px">{sectionHeader.title}</H1>
      <PMedium scale={400} marginBottom="16px">
        {sectionHeader.subtitle.split('\n').map((item) => (
          <Fragment key={uuid()}>
            {item}
            <br />
          </Fragment>
        ))}
      </PMedium>
    </>
  )

  const renderRefused = (
    <>
      <ErrorSign>{statusIcon.canceledIcon}</ErrorSign>
      <H1 margin="40px 0 8px">{sectionHeader.title}</H1>
      <PMedium scale={400} marginBottom="16px">
        {sectionHeader.subtitle}
      </PMedium>
    </>
  )

  const chooseStatus: { [status: string]: JSX.Element } = {
    ANALYSIS: renderAnalysis,
    REFUSED: renderRefused,
  }

  return <InterUIContainer margin="40px 24px 32px">{chooseStatus[status]}</InterUIContainer>
}
