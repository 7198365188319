import { all } from 'typed-redux-saga'

import watchAfterSales from './afterSales/saga'
import watchHiring from './hiring/saga'
import watchValidation from './validation/saga'
import watchVideoUrl from './videoUrl/saga'

export default function* rootSaga() {
  return yield* all([watchHiring(), watchValidation(), watchAfterSales(), watchVideoUrl()])
}
