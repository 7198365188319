import { Reducer } from 'redux'

import { TabState, TabTypes } from './types'

const INITIAL_STATE: TabState = {
  selectedTab: 'Parcela',
}

export const reducer: Reducer<TabState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TabTypes.INSTALLMENT:
      return { ...state, selectedTab: 'Parcela' }
    case TabTypes.AMOUNT:
      return { ...state, selectedTab: 'Valor total' }
    default:
      return state
  }
}

export default reducer
