import { SkeletonBox, SkeletonContent } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface LoadModalityProps {
  qtd?: number
}

export const LoadModality = ({ qtd }: LoadModalityProps) => {
  const renderListModality: number[] = [...Array(qtd).keys()]

  return (
    <>
      {renderListModality.map(() => (
        <SkeletonBox key={uuid()} padding="4px">
          <SkeletonContent>
            <InterUISkeleton height="146px" width="100%" marginBottom="8px" />
            <InterUISkeleton height="16px" width="80%" marginBottom="8px" />
            <InterUISkeleton height="8px" width="40%" marginBottom="4px" />
            <InterUISkeleton height="12px" width="40%" marginBottom="0px" />
          </SkeletonContent>
        </SkeletonBox>
      ))}
    </>
  )
}
