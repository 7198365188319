import InterWebviewBridge, {
  interWbSession,
  IWbTac,
  IWbTic,
  WbEnvironments,
} from '@interco/inter-webview-bridge'

class BridgeService {
  private static interWb = InterWebviewBridge.getInstance()

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER
  }

  static async ticWb(ticPorps: IWbTic): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tic(ticPorps)
    }
  }

  static async tacWb(tacPorps: IWbTac): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tac(tacPorps)
    }
  }
}

export default BridgeService
