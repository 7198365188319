import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { AboutUrlEnum } from 'src/enums/AboutUrl'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PageDirections } from 'src/routes/pageDirections'
import { UrlActions } from 'src/store/ducks/aboutUrl/actions'
import { NavbarActions } from 'src/store/ducks/navbar/actions'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUINavbar } from '@interco/inter-ui-react-lib'

import { BtnRight, ContentNavbar } from './Navbar.styles'

export const Navbar = () => {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const title = useSelector(NavbarActions.getTitle)
  const toggleFaqBtn = useSelector(NavbarActions.getToggleFaqBtn)
  const toggleHomeBtn = useSelector(NavbarActions.getToggleHomeBtn)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)
  const selectedUrl = useSelector(UrlActions.get)
  const toggleBackNavigationBtn = useSelector(NavbarActions.getToggleBackNavigationBtn)

  const isInitialPage = (path: string) => {
    const initialPage = [TypesRoutes.START]

    return initialPage.find((page) => page === path) !== undefined
  }

  const onClickedBack = () => {
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))
    if (isInitialPage(location.pathname)) {
      BaseBridge.openDeepLink('bancointer://seguros')
    } else {
      backNavigation()
    }
    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  const onClickedFaq = () => {
    selectedUrl === AboutUrlEnum.KNOW_MORE
      ? history.push(TypesRoutes.KNOW_MORE)
      : history.push(TypesRoutes.FAQ)
  }

  const iconRightBtn = (
    <>
      {toggleFaqBtn && (
        <BtnRight aria-label="Botão FAQ" data-testid="test-buttonFaq" onClick={onClickedFaq}>
          <Icons.Help color={theme.colors.primary.A500} width={SIZE_MD} height={SIZE_MD} />
        </BtnRight>
      )}

      {toggleHomeBtn && (
        <BtnRight
          aria-label="Botão home"
          data-testid="test-buttonHome"
          onClick={() => BaseBridge.requestGoBack()}
        >
          <Icons.Home color={theme.colors.primary.A500} width={SIZE_MD} height={SIZE_MD} />
        </BtnRight>
      )}
    </>
  )

  return (
    <ContentNavbar>
      <InterUINavbar
        title={title}
        hideBack={!toggleBackNavigationBtn}
        iconRight={iconRightBtn}
        onClickedBack={onClickedBack}
        data-testid="navbar-container"
      />
    </ContentNavbar>
  )
}
