import styled from 'styled-components'

export const Figure = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

export const InformationContent = styled.div`
  width: 100%;
  margin-top: 8px;
`
