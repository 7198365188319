/**
 * Opções disponíveis nos tipos de planos
 */
export enum LabelsBSEnum {
  RESERVE = 'TX_RESERVE',
  ADMIN = 'TX_ADMIN',
  GROUP = 'GROUP',
}

/**
 * Opções disponíveis nos tipos de pagamento
 */
export enum CheckoutEnum {
  HIRE = 'HIRE',
  PENDING = 'PENDING',
}

/**
 * Opções disponíveis nos tipos de pagamento
 */
export enum walletClientIdEnum {
  CONSORTIUM = 'CONSORCIO',
  RECURRENCE = 'RECURRENCE',
}
