import { Reducer } from 'redux'
import { PageTitles } from 'src/enums/PageTitles'

import { NavbarState, NavbarTypes } from './types'

const INITIAL_STATE: NavbarState = {
  title: PageTitles.CONSORTIA,
  toggleFaqBtn: true,
  toggleHomeBtn: false,
  toggleBackNavigationBtn: true,
  direction: 'forward',
  backNavigationHandler: () => undefined,
}

const reducer: Reducer<NavbarState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavbarTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavbarTypes.TOGGLE_FAQ_BTN:
      return { ...state, toggleFaqBtn: action.payload }
    case NavbarTypes.TOGGLE_HOME_BTN:
      return { ...state, toggleHomeBtn: action.payload }
    case NavbarTypes.TOGGLE_BACK_NAVIGATION_BTN:
      return { ...state, toggleBackNavigationBtn: action.payload }
    case NavbarTypes.SET_DIRECTION:
      return { ...state, direction: action.payload }
    case NavbarTypes.SET_BACK_NAVIGATION_HANDLER:
      return { ...state, backNavigationHandler: action.payload }
    default:
      return state
  }
}

export default reducer
