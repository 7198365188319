import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { ErrorDetails, ErrorTypes } from './types'

const show = (data: ErrorDetails) => action(ErrorTypes.SHOW, data)

const hide = () => action(ErrorTypes.HIDE, false)

const get = (state: RootState) => state.error.toggle

const setErrorDetails = (data: ErrorDetails) => action(ErrorTypes.SET_ERROR_DETAILS, data)

const getErrorDetails = (state: RootState) => state.error.details

export const ErrorActions = {
  show,
  hide,
  get,
  setErrorDetails,
  getErrorDetails,
}
