/**
 * Rotas disponíveis na aplicação.
 */
export enum TypesRoutes {
  // HOME
  START = '/',

  // HOME
  HOME = '/home',

  // HIRING
  PRE_SIMULATE = '/pre-simulate',
  LETTERS_CREDIT = '/letters-credit',
  CHECKOUT = '/checkout',
  RECEIPT = '/receipt',

  // AFTER SALES
  MY_CONSORTIA = '/my-consortia',
  MY_CONSORTIA_DETAIL = '/my-consortia-detail',
  EXTRACT = '/extract',
  LATE_INSTALLMENTS = '/late-installments',

  // ABOUT
  FAQ = '/faq',
  KNOW_MORE = '/know-more',

  // PROPOSAL
  PROPOSAL = '/proposal',

  // RECEIPT
  PAID_INSTALLMENTS = '/paid-installments',

  // REQUEST_GO_BACK
  REQUEST_GO_BACK = 'requestGoBack',
}
