import styled from 'styled-components'

export const GroupIcons = styled.div`
  display: flex;
  column-gap: 32px;
`

export const ActionsIcons = styled.div`
  position: relative;
  max-width: 60px;
  text-align: center;
`

export const ContentIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.primary.A100};
  border-radius: 100%;
`

export const QtdExtract = styled.div`
  display: flex;
  position: absolute;
  right: -4px;
  top: -4px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A500};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`
