import { HTMLAttributes } from 'react'

import { useSelector } from 'react-redux'
import { PreSimulateEnum } from 'src/enums/PreSimulate'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { ICard } from 'src/store/ducks/hiring/types'
import { TabActions } from 'src/store/ducks/tab/actions'
import { PLarge, PSmall, PXSmall } from 'src/styles/commons'
import { useTheme } from 'styled-components'

import { InterUIBox, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { InformationContent } from './Card.styles'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  cardDetails: ICard
  onClickedDetail: () => void
}

export const Card = ({ cardDetails, onClickedDetail, ...props }: CardProps) => {
  const theme = useTheme()

  const selectedTab = useSelector(TabActions.get)
  const selectedValue = useSelector(HiringActions.getSelectedValue)

  const { cardTitle, consortiumValue, group, installmentValue, offer, term } = cardDetails

  return (
    <InterUIBox
      direction="column"
      align="flex-start"
      padding="16px 8px 8px"
      onClick={() => onClickedDetail()}
      style={{ position: 'relative' }}
      {...props}
    >
      <PSmall marginBottom="4px">{cardTitle}</PSmall>
      <PLarge
        marginBottom="12px"
        bold
        scale={400}
        color={
          selectedTab === PreSimulateEnum.AMOUNT || !selectedValue
            ? theme.colors.primary.A500
            : theme.colors.neutral.grayscale.A400
        }
      >
        {formatToBRL(consortiumValue)}
      </PLarge>
      <InformationContent className={offer ? 'offer' : ''}>
        <PXSmall marginBottom="4px">A partir de</PXSmall>
        <PLarge
          marginBottom="4px"
          bold
          color={
            selectedTab === PreSimulateEnum.AMOUNT || !selectedValue
              ? theme.colors.neutral.grayscale.A400
              : theme.colors.primary.A500
          }
        >
          {formatToBRL(installmentValue)}
        </PLarge>
        <PXSmall marginBottom="4px">
          Em até <strong>{term} meses</strong>
        </PXSmall>
      </InformationContent>
      <PXSmall marginBottom="0" scale={400}>
        <strong>Grupo:</strong> {group}
      </PXSmall>
      {offer && (
        <InterUITag style={{ position: 'absolute', right: '8px', bottom: '6px' }} variant="primary">
          Oferta
        </InterUITag>
      )}
    </InterUIBox>
  )
}
