/**
 * Opções disponíveis de modalidades
 */
export enum ModalityEnum {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
  PROPERTY = 'PROPERTY',
  PHONE = 'PHONE',
  TRAVEL = 'TRAVEL',
  APPLIANCE = 'APPLIANCE',
  WEDDING = 'WEDDING',
  OTHERS = 'OTHERS',
}
