export enum ValidationTypes {
  GET_ELIGIBLE_CALL_REQUEST = `@validation/GET_ELIGIBLE_CALL_REQUEST`,
}

export interface IEligible {
  eligible: boolean
  message?: string
  title?: string
  type?: string
}

export interface IEligibleRequest {
  onTransmission: (status: boolean) => void
}
