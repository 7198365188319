import { IQuota } from 'src/Interfaces/afterSales'
import { SummaryList } from 'src/components'
import { ISummaryItem } from 'src/components/SummaryList/SummaryList'
import { SummaryListVariantsEnum } from 'src/enums/SummaryListVariants'
import { Div, PLarge, PMedium } from 'src/styles/commons'
import { getDateFromStringDate } from 'src/utils/commons'

import { InterUIAccordion, InterUIBox } from '@interco/inter-ui-react-lib'

interface IQuotaProps {
  quotaInfo: IQuota
}

export const Quota = ({ quotaInfo }: IQuotaProps) => {
  const { quota, group, proposalNumber, dueDate, status, purchaseDate } = quotaInfo

  const summaryList: ISummaryItem[] = [
    {
      id: 0,
      text: 'Número da proposta',
      value: proposalNumber.toString(),
    },
    {
      id: 1,
      text: 'Dia do vencimento',
      value: dueDate,
    },
    {
      id: 2,
      text: 'Situação',
      value: status,
    },
    {
      id: 3,
      text: 'Data da compra',
      value: getDateFromStringDate(purchaseDate).toString(),
      skipMargin: true,
    },
  ]

  const header = (
    <Div>
      <PLarge marginBottom="4px" bold>
        Cota - {quota}
      </PLarge>
      <PMedium scale={400} marginBottom="4px" bold>
        {group}
      </PMedium>
    </Div>
  )
  return (
    <InterUIBox direction="column" align="initial" padding="0px 16px" margin="24px 0px">
      <InterUIAccordion id="accordion-quota" header={header} border="none">
        <SummaryList
          items={summaryList}
          variant={SummaryListVariantsEnum.PM}
          margin="0px 0px 16px"
        />
      </InterUIAccordion>
    </InterUIBox>
  )
}
