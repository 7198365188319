import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { ContainerFullPage, Div, H2, PMedium } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

import { Content } from './KnowMore.styles'

export const KnowMore = () => {
  const theme = useTheme()
  const history = useHistory()

  const colorGrayscale = theme.colors.neutral.grayscale.A500

  const knowMoreIcons = {
    credit: <Icons.Credit width={SIZE_MD} height={SIZE_MD} color={colorGrayscale} />,
    ticket: <Icons.Ticket width={SIZE_MD} height={SIZE_MD} color={colorGrayscale} />,
    tag: <Icons.Tag width={SIZE_MD} height={SIZE_MD} color={colorGrayscale} />,
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIUM,
    hideFaqBtn: true,
    backNavigationHandler: history.goBack,
  })

  return (
    <ContainerFullPage margin="0" height="0px">
      <InterUIContainer margin="24px">
        <H2 marginBottom="16px">Carta de crédito</H2>
        <Content>
          {knowMoreIcons.credit}

          <Div>
            <PMedium bold>O que é uma carta de crédito?</PMedium>
            <PMedium marginBottom="0" scale={400}>
              É um título financeiro com determinado valor de crédito. A carta é entregue ao
              consorciado quando ele é contemplado pelo sorteio, para que ele adquira o bem
              desejado.
            </PMedium>
          </Div>
        </Content>
      </InterUIContainer>
      <InterUIContainer margin="24px">
        <H2 marginBottom="16px">Planos</H2>
        <Content>
          {knowMoreIcons.ticket}

          <Div>
            <PMedium bold>Plano conforto</PMedium>
            <PMedium marginBottom="0" scale={400}>
              Simplificamos ainda mais: no plano conforto, você paga parcelas reduzidas até ser
              contemplado por sorteios ou lances. O restante do valor será recalculado no restante
              das parcelas.
            </PMedium>
          </Div>
        </Content>

        <InterUISeparator variant="small" margin="24px 0" />

        <Content>
          {knowMoreIcons.tag}

          <Div>
            <PMedium bold>Parcela reduzida</PMedium>
            <PMedium marginBottom="0" scale={400}>
              Ofereceremos diferentes reduções para as primeiras parcelas. Assim, você conquista seu
              bem dentro do seu orçamento.
            </PMedium>
          </Div>
        </Content>
      </InterUIContainer>
    </ContainerFullPage>
  )
}
