import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IExtractTransaction } from 'src/Interfaces/afterSales'
import { Button, PaymentMethod } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { CheckoutEnum } from 'src/enums/Checkout'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { H1, H2, PSmall } from 'src/styles/commons'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ContentPending } from './LateInstallments.styles'
import { OpenInvoices } from './OpenInvoices/OpenInvoices'

export const LateInstallments = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(LoadingActions.get)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const consortiumId = useSelector(AfterSalesActions.getSelectedConsortiumId)
  const lateInstallments = useSelector(AfterSalesActions.getLateInstallments)

  const [installmentsChecked, setInstallmentsChecked] =
    useState<IExtractTransaction[]>(lateInstallments)

  useBasePage({
    navbarTitle: PageTitles.PENDING,
    hideFaqBtn: true,
    backNavigationHandler: history.goBack,
  })

  const sumLateInstallments = (installments: IExtractTransaction[]) => {
    let total = 0

    installments.forEach((item) => {
      total += item.value
    })

    return total
  }

  const listInstallmentsNumbers = installmentsChecked
    .map((item) => item.installmentNumber)
    .join(', ')

  const handleClickPayment = async () => {
    if (BaseBridge.isBrowser()) {
      dispatch(
        AfterSalesActions.getPaidInstallmentsRequest({
          history,
          pathname: TypesRoutes.PAID_INSTALLMENTS,
          consortiumId,
          installmentNumber: listInstallmentsNumbers,
        }),
      )
    } else {
      try {
        const amount = sumLateInstallments(installmentsChecked)

        const result = await CheckoutBaseService.contractWallet(`${amount}`)

        if (result.status === 'SUCCESS') {
          dispatch(
            AfterSalesActions.getPaidInstallmentsRequest({
              history,
              pathname: TypesRoutes.PAID_INSTALLMENTS,
              consortiumId,
              installmentNumber: listInstallmentsNumbers,
            }),
          )
        }
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'LateInstallments.payment',
        })

        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const stickyFooter = (
    <>
      <InterUISeparator height="1px" />
      <InterUIContainer margin="24px">
        <Button
          variant="primary"
          onClick={() => handleClickPayment()}
          disabled={installmentsChecked.length === 0 || !formOfPayment.type || isLoading}
        >
          Pagar
        </Button>
      </InterUIContainer>
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="24px">
        <ContentPending>
          <PSmall scale={400} marginBottom="0">
            Valor Total
          </PSmall>
          <H1>{formatToBRL(sumLateInstallments(installmentsChecked))}</H1>
        </ContentPending>

        <OpenInvoices
          installmentsData={lateInstallments}
          dataState={[installmentsChecked, setInstallmentsChecked]}
        />
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="24px">
        <H2 marginBottom="32px">Como você quer pagar?</H2>

        <PaymentMethod
          type={CheckoutEnum.PENDING}
          proposalUuid={consortiumId}
          amount={sumLateInstallments(installmentsChecked)}
          installmentNumber={listInstallmentsNumbers}
        />
      </InterUIContainer>
    </InterUIContainer>
  )
}
