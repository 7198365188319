/**
 * Variantes disponíveis no SummaryList.
 */
export enum SummaryListVariantsEnum {
  PXS = 'PXS',
  PS = 'PS',
  PM = 'PM',
  PL = 'PL',
  PLX = 'PLX',
}
