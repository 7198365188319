import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { SkeletonContent } from '../Skeleton.styles'
import { LoadGroup } from '../components/LoadGroup/LoadGroup'

export const LoadMyConsortia = () => (
  <SkeletonContent data-testid="loading-my-consortia">
    <InterUIContainer margin="24px">
      <LoadGroup qtd={6} />
    </InterUIContainer>
  </SkeletonContent>
)
