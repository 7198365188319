import { applyMiddleware, createStore, Store, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import rootReducer from './ducks/rootReducer'
import rootSaga from './ducks/rootSaga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store: Store<RootState> = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export type RootState = ReturnType<typeof rootReducer>

export const configurePersistor = (store: Store<RootState>) => persistStore(store)

const store = configureStore()

export const persistor = configurePersistor(store)

export default store
