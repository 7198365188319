import styled from 'styled-components'

/**
 * Prefixo a ser exibido ao lado do input pricing
 */
export const PrefixPricing = styled.span`
  padding: 16px 0px 4px 0px;
  font-family: 'Sora';
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral.grayscale.A400};
`

export const ContentPricing = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A300};
  width: fit-content;
  margin: 0 auto 8px;

  input {
    border: none;
  }
`
