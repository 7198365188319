import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'
import { action } from 'typesafe-actions'

import { RootState } from '../..'
import {
  HiringTypes,
  ICardsResponse,
  ICheckout,
  ICheckoutRequest,
  IHome,
  ILettersCreditRequest,
  IModality,
  IPlanSelected,
  IProposalRequest,
  IProposalResponse,
  IReceipt,
  IReceiptPaymentRequest,
  ISubProductRequest,
} from './types'

const getHomeRequest = (data?: SagaWithHistory) => action(HiringTypes.GET_HOME_CALL_REQUEST, data)

const getHome = (state: RootState) => state.hiring.home

const setHome = (data: IHome) => action(HiringTypes.SET_HOME, data)

const getSubproductRequest = (data: ISubProductRequest) =>
  action(HiringTypes.GET_SUBPRODUCT_REQUEST, data)

const getSelectedModality = (state: RootState) => state.hiring.selectedModality

const setSelectedModality = (data: IModality) => action(HiringTypes.SET_SELECTED_MODALITY, data)

const getSelectedValue = (state: RootState) => state.hiring.selectedValue

const setSelectedValue = (data: number) => action(HiringTypes.SET_SELECTED_VALUE, data)

const getCardsRequest = (data: ILettersCreditRequest) => action(HiringTypes.GET_CARDS_REQUEST, data)

const getCards = (state: RootState) => state.hiring.cards

const setCards = (data: ICardsResponse) => action(HiringTypes.SET_CARDS, data)

const getCheckoutRequest = (data: ICheckoutRequest) =>
  action(HiringTypes.GET_CHECKOUT_REQUEST, data)

const getCheckout = (state: RootState) => state.hiring.checkout

const setCheckout = (data: ICheckout) => action(HiringTypes.SET_CHECKOUT, data)

const getProposalRequest = (data: IProposalRequest) =>
  action(HiringTypes.GET_PROPOSAL_REQUEST, data)

const getProposal = (state: RootState) => state.hiring.proposal

const setProposal = (data: IProposalResponse) => action(HiringTypes.SET_PROPOSAL, data)

const getReceiptRequest = (data: IReceiptPaymentRequest) =>
  action(HiringTypes.GET_RECEIPT_REQUEST, data)

const getReceipt = (state: RootState) => state.hiring.receipt

const setReceipt = (data: IReceipt) => action(HiringTypes.SET_RECEIPT, data)

const getCheckedConsortiumInsurance = (state: RootState) => state.hiring.checkedConsortiumInsurance

const setCheckedConsortiumInsurance = (data: boolean) =>
  action(HiringTypes.SET_CHECKED_CONSORTIUM_INSURANCE, data)

const getPlan = (state: RootState) => state.hiring.plan

const setPlan = (data: IPlanSelected) => action(HiringTypes.SET_PLAN, data)

export const HiringActions = {
  getHome,
  setHome,
  getHomeRequest,
  getSubproductRequest,
  getSelectedModality,
  setSelectedModality,
  getSelectedValue,
  setSelectedValue,
  getCardsRequest,
  getCards,
  setCards,
  getCheckoutRequest,
  getCheckout,
  setCheckout,
  getProposalRequest,
  getProposal,
  setProposal,
  getReceiptRequest,
  getReceipt,
  setReceipt,
  getCheckedConsortiumInsurance,
  setCheckedConsortiumInsurance,
  getPlan,
  setPlan,
}
