import { Fragment } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IExtractTransaction } from 'src/Interfaces/afterSales'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { ContainerFullPage, H3, PSmall } from 'src/styles/commons'
import { getMonthNameFromStringDate } from 'src/utils/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIListDescription, InterUISeparator, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'

export const Extract = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const iconDeposit = (
    <Icons.Deposit width={SIZE_MD} height={SIZE_MD} color={theme.colors.neutral.grayscale.A500} />
  )
  const iconArrow = (
    <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={theme.colors.primary.A500} />
  )

  const consortiumExtract = useSelector(AfterSalesActions.getConsortiumExtract)

  useBasePage({
    navbarTitle: PageTitles.EXTRACT,
    hideFaqBtn: true,
    backNavigationHandler: history.goBack,
  })

  const handleClick = () => {
    dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))
    history.push(TypesRoutes.LATE_INSTALLMENTS)
  }

  const selectColorInstallment = (statusTrasaction: string) => {
    switch (statusTrasaction) {
      case 'pagamento atrasado':
        return theme.colors.feedback.error.A100
      case 'pagamento em análise':
        return theme.colors.feedback.warning.A100
      default:
        return theme.colors.feedback.success.A100
    }
  }

  const renderTransactions = (transactions: IExtractTransaction[]) => {
    const lastTransactionId = transactions.slice(-1)[0].installmentNumber ?? 0
    return transactions.map((transaction) => (
      <Fragment key={uuid()}>
        <InterUIListDescription
          key={transaction.installmentNumber}
          margin="0 0 24px"
          iconLeft={iconDeposit}
          iconRight={transaction.clickable ? iconArrow : undefined}
          AlignOnlyRightIcon="center"
          onClick={transaction.clickable ? () => handleClick() : undefined}
        >
          <PSmall marginBottom="4px" bold>
            {transaction.description}
          </PSmall>
          <PSmall marginBottom="4px" bold>
            {formatToBRL(transaction.value)}
          </PSmall>
          <InterUITag
            backgroundColor={selectColorInstallment(transaction.status.toLocaleLowerCase())}
            color={theme.colors.base.black}
          >
            {transaction.status}
          </InterUITag>
        </InterUIListDescription>
        {transaction.installmentNumber !== lastTransactionId && (
          <InterUISeparator height="1px" margin="24px 0 16px" />
        )}
      </Fragment>
    ))
  }

  // TODO: Criar o skeleton / loading
  return (
    <ContainerFullPage margin="24px" height="0px">
      {consortiumExtract.length > 0 ? (
        consortiumExtract.map((extract) => (
          <Fragment key={uuid()}>
            <H3>{getMonthNameFromStringDate(extract.date)}</H3>
            <InterUISeparator height="1px" margin="24px 0 16px" />
            {renderTransactions(extract.transactions)}
          </Fragment>
        ))
      ) : (
        // TODO: Ver como será a tela em caso de dados vazio
        <></>
      )}
    </ContainerFullPage>
  )
}
