import { H2, PMedium } from 'src/styles/commons'

import { InterUIContainer } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

import { Box, Grid } from './ConsortiumData.styles'

interface IConsortiumProps {
  group: number
  amount: string
  cardValue: string
  planInstallment: string
  cardInstallment: number
  dateRequest: string
  datePayment: string
  paymentType: string
}

export const ConsortiumData = ({
  group,
  amount,
  cardValue,
  planInstallment,
  cardInstallment,
  dateRequest,
  datePayment,
  paymentType,
}: IConsortiumProps) => {
  const consortiumDataView = [
    {
      label: 'Grupo',
      value: group,
    },
    {
      label: 'Valor da parcela',
      value: amount,
    },
    {
      label: 'Valor da carta',
      value: cardValue,
    },
    {
      label: 'Prazo do plano',
      value: planInstallment,
    },
    {
      label: 'Número de parcelas',
      value: cardInstallment,
    },
    {
      label: 'Data de solicitação',
      value: dateRequest,
    },
    {
      label: 'Data de cobrança',
      value: datePayment,
    },
    {
      label: 'Forma de pagamento',
      value: paymentType,
    },
  ]

  return (
    <InterUIContainer margin="32px 24px">
      <H2>Dados do Consórcio</H2>

      <Grid>
        {consortiumDataView.map((item, index) => (
          <Box key={uuid()} className={index === 0 ? 'first_box' : ''}>
            <PMedium margin="16px 0 0" scale={400}>
              {item.label}
            </PMedium>

            <PMedium margin="4px 0 0" bold>
              {item.value}
            </PMedium>
          </Box>
        ))}
      </Grid>
    </InterUIContainer>
  )
}
