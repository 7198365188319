import styled from 'styled-components'

interface ISkeletonBoxProps {
  padding?: string
  width?: string
}

interface ISkeletonContentProps {
  display?: string
  flexDirection?: string
  alignItems?: string
}

export const SkeletonBox = styled.div<ISkeletonBoxProps>`
  width: ${(props) => props.width || '100%'};
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: ${(props) => props.padding || '16px'};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A100};
`

export const SkeletonGrid = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`

export const SkeletonContent = styled.div<ISkeletonContentProps>`
  flex: 1;
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
`
