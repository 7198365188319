import { combineReducers } from 'redux'

import url from './aboutUrl'
import afterSales from './afterSales'
import checkout from './checkout'
import error from './error'
import hiring from './hiring'
import loading from './loading'
import navbar from './navbar'
import newRelic from './newRelic'
import pathparams from './pathparams'
import tab from './tab'
import video from './videoUrl'

const rootReducer = combineReducers({
  navbar,
  loading,
  error,
  pathparams,
  hiring,
  tab,
  url,
  afterSales,
  video,
  newRelic,
  checkout,
})

export default rootReducer
