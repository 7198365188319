import BaseService from 'src/config/bridge/BaseService'
import { IVideoResponse } from 'src/store/ducks/videoUrl/types'

import { MockService } from './mockService'

export default class FaqService {
  static getVideoUrl() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IVideoResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/faq-video-url`,
      headers,
    })
  }
}
