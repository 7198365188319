import {
  IConsortiumDetails,
  IExtract,
  IExtractTransaction,
  IMyConsortia,
  IPaidInstallments,
} from 'src/Interfaces/afterSales'

export enum AfterSalesTypes {
  GET_MY_CONSORTIA_REQUEST = `@after-sales/GET_MY_CONSORTIA_REQUEST`,
  GET_MY_CONSORTIA = `@after-sales/GET_MY_CONSORTIA`,
  SET_MY_CONSORTIA = `@after-sales/SET_MY_CONSORTIA`,
  GET_SELECTED_CONSORTIUM_ID = `@after-sales/GET_SELECTED_CONSORTIUM_ID`,
  SET_SELECTED_CONSORTIUM_ID = `@after-sales/SET_SELECTED_CONSORTIUM_ID`,
  GET_CONSORTIUM_DETAILS_REQUEST = `@after-sales/GET_CONSORTIUM_DETAILS_REQUEST`,
  GET_CONSORTIUM_DETAILS = `@after-sales/GET_CONSORTIUM_DETAILS`,
  SET_CONSORTIUM_DETAILS = `@after-sales/SET_CONSORTIUM_DETAILS`,
  GET_CONSORTIUM_EXTRACT_REQUEST = `@after-sales/GET_CONSORTIUM_EXTRACT_REQUEST`,
  GET_CONSORTIUM_EXTRACT = `@after-sales/GET_CONSORTIUM_EXTRACT`,
  SET_CONSORTIUM_EXTRACT = `@after-sales/SET_CONSORTIUM_EXTRACT`,
  GET_LATE_INSTALLMENTS = `@after-sales/GET_LATE_INSTALLMENTS`,
  SET_LATE_INSTALLMENTS = `@after-sales/SET_LATE_INSTALLMENTS`,
  GET_PAID_INSTALLMENTS_REQUEST = `@after-sales/GET_PAID_INSTALLMENTS_REQUEST`,
  GET_PAID_INSTALLMENTS = `@after-sales/GET_PAID_INSTALLMENTS`,
  SET_PAID_INSTALLMENTS = `@after-sales/SET_PAID_INSTALLMENTS`,
}

export interface AfterSalesState {
  readonly myConsortia: IMyConsortia
  readonly selectedConsortiumId: string
  readonly consortiumDetails: IConsortiumDetails
  readonly consortiumExtract: IExtract[]
  readonly lateInstallments: IExtractTransaction[]
  readonly paidInstallments: IPaidInstallments
}
