import { Fragment } from 'react'

import { Div, PMedium } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { uuid } from '@interco/lib-util'

import { FaqContent } from './FaqQuestions.styles'

export const FaqQuestions = () => {
  const theme = useTheme()

  const grayscaleA500 = theme.colors.neutral.grayscale.A500

  const faqIcons = {
    help: <Icons.Help width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    credit: <Icons.Credit width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    ticket: <Icons.Ticket width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    noTaxes: <Icons.NoTaxes width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    profitability: <Icons.Profitability width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
  }

  const faqQuestions = [
    {
      id: 0,
      icon: faqIcons.help,
      question: 'Como funciona?',
      answer:
        'Todos os meses, o grupo paga um valor que será somado a uma poupança. Ao final do período contratado, o dinheiro é usado na aquisição do bem ou serviço.',
    },
    {
      id: 1,
      icon: faqIcons.credit,
      question: 'Consórcio tem taxas?',
      answer:
        'O consórcio é uma modalidade de compra parcelada sem juros. A única cobrança é a taxa de administração dos serviços.',
    },
    {
      id: 2,
      icon: faqIcons.ticket,
      question: 'Antecipação de contrato',
      answer:
        'Se você já tiver um dinheiro extra, pode usá-lo como lance para antecipar as parcelas do seu contrato.',
    },
    {
      id: 3,
      icon: faqIcons.profitability,
      question: 'Como ser contemplado antes?',
      answer:
        'Todos os meses acontecem os sorteios. A pessoa sorteada, conquista o bem ou serviço e não precisa finalizar o contrato. Ao dar um lance, você tem mais chances de ser contemplado no consórcio.',
      answerTwo: 'Você receberá uma carta de crédito.',
      answerThree:
        'Se for contemplado em um sorteio ou ao final do contrato, você receberá um título financeiro com o valor do seu bem ou serviço para efetuar a sua compra.',
    },
  ]

  return (
    <>
      {faqQuestions.map((item, index) => {
        const { icon, question, answer, answerTwo, answerThree } = item
        const id = uuid()

        return (
          <Fragment key={id}>
            <FaqContent id={id} data-testid={`faq-${index}`}>
              {icon}

              <Div>
                <PMedium bold>{question}</PMedium>
                <PMedium marginBottom="0" scale={400}>
                  {answer}
                </PMedium>

                {answerTwo && (
                  <PMedium margin="16px 0" scale={400}>
                    {answerTwo}
                  </PMedium>
                )}

                {answerThree && (
                  <PMedium marginBottom="0" scale={400}>
                    {answerThree}
                  </PMedium>
                )}
              </Div>
            </FaqContent>
          </Fragment>
        )
      })}
    </>
  )
}
