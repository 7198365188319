import styled from 'styled-components'

export const Amount = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`
export const Filters = styled.div`
  overflow: auto;
  white-space: nowrap;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;
  gap: 24px 16px;
`

export const NoCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 96px;
  text-align: center;
`
