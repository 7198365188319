import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { PathParamsTypes } from './types'

const getPrefixPathParams = (state: RootState) => state.pathparams.prefixPathParams

const setPrefixPathParams = (data: string) => action(PathParamsTypes.SET_PREFIX_PATH_PARAMS, data)

const getUtmCampaignPathParams = (state: RootState) => state.pathparams.utmCampaignParams

const setUtmCampaignPathParams = (data: string) =>
  action(PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS, data)

const getDeepLinkCardsFilter = (state: RootState) => state.pathparams.deepLinkCardsFilter

const setDeepLinkCardsFilter = (data: string) =>
  action(PathParamsTypes.SET_DEEPLINK_CARDS_FILTER, data)

const getAccessedViaDeepLink = (state: RootState) => state.pathparams.accessedViaDeepLink

const setAccessedViaDeepLink = (data: boolean) =>
  action(PathParamsTypes.SET_ACCESSED_VIA_DEEPLINK, data)

export const PathParamsActions = {
  getPrefixPathParams,
  setPrefixPathParams,
  getUtmCampaignPathParams,
  setUtmCampaignPathParams,
  getDeepLinkCardsFilter,
  setDeepLinkCardsFilter,
  getAccessedViaDeepLink,
  setAccessedViaDeepLink,
}
