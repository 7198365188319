import { SkeletonBox, SkeletonContent, SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface LoadPlansProps {
  qtd?: number
}

export const LoadPlans = ({ qtd }: LoadPlansProps) => {
  const renderListPlans: number[] = [...Array(qtd).keys()]

  return (
    <>
      {renderListPlans.map(() => (
        <SkeletonBox key={uuid()} width="auto">
          <SkeletonGrid>
            <SkeletonContent>
              <InterUISkeleton height="16px" width="50%" marginBottom="8px" />
              <InterUISkeleton height="12px" width="40%" marginBottom="4px" />
              <InterUISkeleton height="16px" width="80%" marginBottom="0px" />
            </SkeletonContent>
            <InterUISkeleton variant="circle" height="32px" width="32px" marginBottom="0px" />
          </SkeletonGrid>
        </SkeletonBox>
      ))}
    </>
  )
}
