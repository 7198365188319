import styled from 'styled-components'

export const InformationContent = styled.div`
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A100};
  border-radius: 4px;
  box-sizing: border-box;

  &.offer {
    background-color: ${(props) => props.theme.colors.primary.A100};
  }
`
