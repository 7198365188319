import styled from 'styled-components'

export const ContentPending = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
`
