import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { DeepLinkHandler } from 'src/routes/deepLinkHandler'
import { IBanner } from 'src/store/ducks/hiring/types'

import { Carousel as Slider } from '@interco/inter-ui/components/Carousel'

import { BannerImage, ContentSlider } from './Carousel.styles'

interface ICarouselProps {
  banners: IBanner[]
}

export const Carousel = ({ banners }: ICarouselProps) => {
  const deepLinkHandler = DeepLinkHandler()

  const handleTagBanner = ({ title, order, route }: IBanner) => {
    BaseBridge.requestAnalytics(TAGS.C_CONSOR_BANNER_DISPLAY.screen, {
      ...TAGS.C_CONSOR_BANNER_DISPLAY,
      action_id: title,
      content_id: title,
      position: order.toString(),
      link: route,
    })
  }

  const handleClickBanner = (route: string) => {
    deepLinkHandler(route)
  }

  return (
    <ContentSlider>
      <Slider
        dots
        arrows={false}
        infinite={false}
        speed={500}
        slidesToShow={1}
        dotsType="medium"
        verticalMode={false}
        centerMode
        centerPadding="24px"
        className="slider"
      >
        {banners.map((banner) => (
          <BannerImage
            data-testid="test-banner"
            key={banner.title}
            onClick={() => handleClickBanner(banner.route)}
          >
            <img src={banner.url} alt={banner.title} />
            {handleTagBanner(banner)}
          </BannerImage>
        ))}
      </Slider>
    </ContentSlider>
  )
}
