import { ICancelation, IContact, IPaymentMethod } from 'src/Interfaces/afterSales'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { Div, PMedium, PSmall } from 'src/styles/commons'
import { SIZE_MD, SIZE_SM } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIAccordion, InterUIListDescription } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface IContactItem {
  id: number
  icon: JSX.Element
  text: string
  category: string
  description?: string
}

interface IContactProps {
  info: IContact[]
  paymentMethod: IPaymentMethod
  cancelation: ICancelation
}

const contactFilterValue = (contacts: IContact[], category: string) => {
  const filteredContact = contacts.filter((contact) => contact.category === category)
  return filteredContact[0]?.value ?? ''
}

const fixUrl = (url: string) => (url.indexOf('http') < 0 ? `http://${url}` : url)

const handleItem = (category: string, value: string) => {
  switch (category) {
    case 'Site':
      BaseBridge.openLinkOnBrowser(fixUrl(value))
      break

    case 'WhatsApp':
      BaseBridge.openWhatsApp(value)
      break

    case 'Celular':
      BaseBridge.callPhoneNumber(value)
      break

    default:
      break
  }
}

export const Information = ({ info, paymentMethod, cancelation }: IContactProps) => {
  const theme = useTheme()

  const grayscaleA500 = theme.colors.neutral.grayscale.A500

  const icons = {
    phone: <Icons.Phone width={SIZE_SM} height={SIZE_SM} color={grayscaleA500} />,
    phoneCall: <Icons.PhoneCall width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    computerCall: <Icons.ComputerCall width={SIZE_SM} height={SIZE_SM} color={grayscaleA500} />,
    whatsApp: <Icons.WhatsApp width={SIZE_SM} height={SIZE_SM} color={grayscaleA500} />,
    cancelled: <Icons.Canceled width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    wallet: <Icons.Wallet width={SIZE_MD} height={SIZE_MD} color={grayscaleA500} />,
    walletSmall: <Icons.Wallet width={SIZE_SM} height={SIZE_SM} color={grayscaleA500} />,
    email: <Icons.Email width={SIZE_SM} height={SIZE_SM} color={grayscaleA500} />,
  }

  const siteText = contactFilterValue(info, 'Site')
  const whatsText = contactFilterValue(info, 'Whatsapp')
  const phoneText = contactFilterValue(info, 'Celular')

  const emailTextCancellation = contactFilterValue(cancelation.contact, 'Email')
  const whatsTextCancellation = contactFilterValue(cancelation.contact, 'Whatsapp')
  const phoneTextCancellation = contactFilterValue(cancelation.contact, 'Celular')

  const WORKING_DAYS_MSG = 'De segunda a sexta-feira, das 8h às 18h, exceto feriados.'

  const contact: IContactItem[] = [
    {
      id: 0,
      icon: icons.computerCall,
      text: siteText,
      category: 'Site',
    },
    {
      id: 1,
      icon: icons.whatsApp,
      text: whatsText,
      category: 'WhatsApp',
      description: WORKING_DAYS_MSG,
    },
    {
      id: 2,
      icon: icons.phone,
      text: phoneText,
      category: 'Celular',
      description: WORKING_DAYS_MSG,
    },
  ]

  const contactCancellation: IContactItem[] = [
    {
      id: 0,
      icon: icons.email,
      text: emailTextCancellation,
      category: 'Email',
    },
    {
      id: 1,
      icon: icons.whatsApp,
      text: whatsTextCancellation,
      category: 'WhatsApp',
      description: WORKING_DAYS_MSG,
    },
    {
      id: 2,
      icon: icons.phone,
      text: phoneTextCancellation,
      category: 'Celular',
      description: WORKING_DAYS_MSG,
    },
  ]

  const headerContact = (
    <>
      {icons.phoneCall}
      <PMedium bold margin="0px 0px 0px 16px">
        Contato
      </PMedium>
    </>
  )

  const headerPayment = (
    <>
      {icons.wallet}
      <PMedium bold margin="0px 0px 0px 16px">
        Forma de pagamento
      </PMedium>
    </>
  )

  const headerCancellation = (
    <>
      {icons.cancelled}
      <PMedium bold margin="0px 0px 0px 16px">
        Cancelamento
      </PMedium>
    </>
  )

  const renderContacts = contact.map((item) => (
    <Div
      display="flex"
      gap="8px"
      alignItems="center"
      padding="0 0 16px"
      key={uuid()}
      onClick={() => handleItem(item.category, item.text)}
    >
      {item.icon}
      <Div>
        <PMedium marginBottom="0" bold color={theme.colors.primary.A500}>
          {item.text}
        </PMedium>
        <PSmall marginBottom="0" scale={400}>
          {item.description}
        </PSmall>
      </Div>
    </Div>
  ))

  const renderCancellation = contactCancellation.map((item) => (
    <Div
      display="flex"
      gap="8px"
      alignItems="center"
      padding="0 0 16px"
      key={uuid()}
      onClick={() => handleItem(item.category, item.text)}
    >
      {item.icon}
      <Div>
        <PMedium marginBottom="0" bold color={theme.colors.primary.A500}>
          {item.text}
        </PMedium>
        <PSmall marginBottom="0" scale={400}>
          {item.description}
        </PSmall>
      </Div>
    </Div>
  ))

  return (
    <>
      <InterUIAccordion
        id="accordion-information"
        header={headerContact}
        border="bottom"
        margin="24px 0px 0px"
      >
        {renderContacts}
      </InterUIAccordion>

      <InterUIAccordion
        id="accordion-payment"
        data-testid="accordion-payment"
        header={headerPayment}
        border="bottom"
        margin="0px"
      >
        <InterUIListDescription
          iconLeft={icons.walletSmall}
          withBorder
          alignAllItems="center"
          borderColor={theme.colors.neutral.grayscale.A100}
          style={{ backgroundColor: theme.colors.neutral.grayscale.A100 }}
        >
          <PMedium marginBottom="0" bold>
            {paymentMethod.description}
          </PMedium>

          {paymentMethod.lastDigits && (
            <PMedium margin="8px 0 0" scale={400}>
              {`•••• ${paymentMethod.lastDigits}`}
            </PMedium>
          )}
        </InterUIListDescription>
      </InterUIAccordion>

      <InterUIAccordion
        id="accordion-cancellation"
        header={headerCancellation}
        border="none"
        margin="0px"
      >
        {renderCancellation}
      </InterUIAccordion>
    </>
  )
}
