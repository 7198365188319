import { Reducer } from 'redux'

import { LoadingState, LoadingTypes } from './types'

const INITIAL_STATE: LoadingState = {
  toggle: false,
}

export const reducer: Reducer<LoadingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoadingTypes.SHOW:
      return { ...state, toggle: true }
    case LoadingTypes.HIDE:
      return { ...state, toggle: false }
    default:
      return state
  }
}

export default reducer
