import { Reducer } from 'redux'

import { NewRelicState, NewRelicTypes } from './types'

const INITIAL_STATE: NewRelicState = {
  context: '',
  segmentation: '',
  appVersion: '',
}

const reducer: Reducer<NewRelicState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewRelicTypes.SET_USER_CONTEXT:
      return { ...state, context: action.payload }
    case NewRelicTypes.SET_USER_SEGMENTATION:
      return { ...state, segmentation: action.payload }
    case NewRelicTypes.SET_USER_APP_VERSION:
      return { ...state, appVersion: action.payload }
    default:
      return state
  }
}

export default reducer
