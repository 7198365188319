import { action } from 'typesafe-actions'

import { LoadingTypes } from './types'
import { RootState } from '../..'

const get = (state: RootState) => state.loading.toggle

const show = () => action(LoadingTypes.SHOW)

const hide = () => action(LoadingTypes.HIDE)

export const LoadingActions = {
  get,
  show,
  hide,
}
