import { Reducer } from 'redux'

import { IVideoResponse, VideoUrlState, VideoUrlTypes } from './types'

const INITIAL_STATE: VideoUrlState = {
  videoUrl: {} as IVideoResponse,
}

const reducer: Reducer<VideoUrlState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VideoUrlTypes.SET_VIDEO_URL:
      return { ...state, videoUrl: action.payload }
    default:
      return state
  }
}

export default reducer
