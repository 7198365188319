import BaseBridge from 'src/config/bridge/BaseBridge'
import FaqService from 'src/services/FaqService'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, takeLatest } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { VideoUrlActions } from './actions'
import { VideoUrlTypes } from './types'

function* getVideoUrl({ payload }: AnyAction) {
  try {
    BaseBridge.requestAnalytics('C_FRONT_ISCON_VIDEO_BEFORE', {
      file: 'videoUrl/saga',
      action: 'Antes de chamar FaqService.getVideoUrl',
    })
    const videoUrlResponse = yield* call(FaqService.getVideoUrl)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_VIDEO_AFTER', {
      file: 'videoUrl/saga',
      action: 'Response do FaqService.getVideoUrl',
      response: JSON.stringify(videoUrlResponse.object),
    })

    yield* put(VideoUrlActions.setVideoUrl(videoUrlResponse.object))
    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getVideoUrl')
  }
}

export default function* watchVideoUrl() {
  yield* takeLatest(VideoUrlTypes.GET_VIDEO_URL_REQUEST, getVideoUrl)
}
