import { Reducer } from 'redux'
import {
  IConsortiumDetails,
  IExtract,
  IExtractTransaction,
  IMyConsortia,
  IPaidInstallments,
} from 'src/Interfaces/afterSales'

import { AfterSalesState, AfterSalesTypes } from './types'

const INITIAL_STATE: AfterSalesState = {
  myConsortia: {} as IMyConsortia,
  consortiumDetails: {} as IConsortiumDetails,
  selectedConsortiumId: '',
  consortiumExtract: [] as IExtract[],
  lateInstallments: [] as IExtractTransaction[],
  paidInstallments: {} as IPaidInstallments,
}

const reducer: Reducer<AfterSalesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AfterSalesTypes.SET_MY_CONSORTIA:
      return { ...state, myConsortia: action.payload }
    case AfterSalesTypes.SET_SELECTED_CONSORTIUM_ID:
      return { ...state, selectedConsortiumId: action.payload }
    case AfterSalesTypes.SET_CONSORTIUM_DETAILS:
      return { ...state, consortiumDetails: action.payload }
    case AfterSalesTypes.SET_CONSORTIUM_EXTRACT:
      return { ...state, consortiumExtract: action.payload }
    case AfterSalesTypes.SET_LATE_INSTALLMENTS:
      return { ...state, lateInstallments: action.payload }
    case AfterSalesTypes.SET_PAID_INSTALLMENTS:
      return { ...state, paidInstallments: action.payload }

    default:
      return state
  }
}

export default reducer
