/**
 * Opções disponíveis na listagem de consórcios.
 */
export enum ConsortiumStatus {
  ACTIVE = 'active',
  ANALYSIS = 'analysis',
  REFUSED = 'refused',
  LATE_INSTALLMENT = 'lateInstallment',
  CANCELED = 'canceled',
  DONE = 'done',
}
