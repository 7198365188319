import { IGroup } from 'src/Interfaces/afterSales'
import { PMedium, PSmall } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIListDescription, InterUIProgressBar, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'

interface ProgressGroupProps {
  category: string
  groups: IGroup[]
  selectedConsortium: (id: string) => void
}

export const ProgressGroup = ({ category, groups, selectedConsortium }: ProgressGroupProps) => {
  const theme = useTheme()

  const iconArrow = (
    <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={theme.colors.primary.A500} />
  )

  const themeFeedback = theme.colors.feedback

  const chooseInfo: {
    [category: string]: { label: string; bgColor: string; circle: string }
  } = {
    analysis: { label: 'Em análise', bgColor: themeFeedback.warning.A100, circle: '' },
    refused: { label: 'Contratação recusada', bgColor: themeFeedback.error.A100, circle: '' },
    active: {
      label: 'Ativo',
      bgColor: themeFeedback.success.A100,
      circle: themeFeedback.success.A500,
    },
    cancelled: {
      label: 'Cancelado',
      bgColor: themeFeedback.error.A100,
      circle: themeFeedback.error.A500,
    },
    lateInstallments: {
      label: 'Parcela atrasada',
      bgColor: themeFeedback.warning.A100,
      circle: themeFeedback.warning.A500,
    },
    done: {
      label: 'Finalizado',
      bgColor: theme.colors.auxiliary.raspberry.A100,
      circle: theme.colors.auxiliary.raspberry.A500,
    },
  }

  return (
    <>
      {groups.map((group) => (
        <InterUIListDescription
          key={uuid()}
          id={`group-${group.consortiumId}`}
          data-testid={`group-${group.consortiumId}`}
          iconLeft={
            <InterUIProgressBar
              progressColor={chooseInfo[category].circle}
              progress={group.percent}
              status="circle"
            >
              <PSmall marginBottom="0" bold>
                {`${group.percent ?? 0}%`}
              </PSmall>
            </InterUIProgressBar>
          }
          iconRight={iconArrow}
          withBorder
          alignAllItems="center"
          margin="0 0 16px"
          onClick={() => selectedConsortium(group.consortiumId)}
        >
          {chooseInfo[category].label && (
            <InterUITag
              margin="0 0 4px 0"
              color={theme.colors.neutral.grayscale.A500}
              backgroundColor={chooseInfo[category].bgColor}
            >
              {chooseInfo[category].label}
            </InterUITag>
          )}
          <PMedium marginBottom="4px" bold>
            {`${group.title}`}
          </PMedium>
          <PSmall marginBottom="4px" scale={400}>
            {`Valor da carta: ${formatToBRL(Number(group.cardValue))}`}
          </PSmall>
          {group.paidInstallments && (
            <PSmall marginBottom="0px" scale={400}>
              {`Parcelas pagas: ${group.paidInstallments}`}
            </PSmall>
          )}
        </InterUIListDescription>
      ))}
    </>
  )
}
