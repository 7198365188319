import { useState } from 'react'

import * as PDFJS from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { HiringActions } from 'src/store/ducks/hiring/actions'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'

import { LoadContainer, PdfContainer } from './Proposal.styles'

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker

export const Proposal = () => {
  const history = useHistory()
  const { proposalUrl } = useSelector(HiringActions.getProposal)

  const [enabledButton, setEnabledButton] = useState(true)

  useBasePage({
    navbarTitle: PageTitles.PROPOSAL,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
  })

  const stickyFooter = (
    <>
      {enabledButton && (
        <InterUIContainer margin="24px">
          <Button
            variant="primary"
            margin="0 0 16px"
            onClick={() => {
              history.goBack()
            }}
          >
            Voltar
          </Button>
        </InterUIContainer>
      )}
    </>
  )

  const loading = (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="0px">
      {proposalUrl && (
        <PdfContainer>
          <Viewer
            fileUrl={proposalUrl}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      )}
    </InterUIContainer>
  )
}
