import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'
import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { IVideoResponse, VideoUrlTypes } from './types'

const getVideoUrlRequest = (data?: SagaWithHistory) =>
  action(VideoUrlTypes.GET_VIDEO_URL_REQUEST, data)

const getVideoUrl = (state: RootState) => state.video.videoUrl

const setVideoUrl = (data: IVideoResponse) => action(VideoUrlTypes.SET_VIDEO_URL, data)

export const VideoUrlActions = {
  getVideoUrlRequest,
  getVideoUrl,
  setVideoUrl,
}
