import { Dispatch, SetStateAction } from 'react'

import { useDispatch } from 'react-redux'
import { IExtractTransaction } from 'src/Interfaces/afterSales'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { Div, H3, PSmall } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUICheckBox } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

interface IOpenInvoicesProps {
  installmentsData: IExtractTransaction[]
  dataState: [IExtractTransaction[], Dispatch<SetStateAction<IExtractTransaction[]>>]
}

export const OpenInvoices = ({ dataState, installmentsData }: IOpenInvoicesProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [installmentsChecked, setInstallmentsChecked] = dataState

  const iconDeposit = (
    <Icons.Deposit width={SIZE_MD} height={SIZE_MD} color={theme.colors.neutral.grayscale.A500} />
  )

  const isSelected = (item: IExtractTransaction) =>
    item ? installmentsChecked.includes(item) : false

  const selectInstallments = (item: IExtractTransaction) => {
    const checkItem = installmentsChecked.some(
      (data) => data.installmentNumber === item.installmentNumber,
    )

    dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))

    if (checkItem) {
      const filteredInstallments = installmentsChecked.filter(
        (data) => data.installmentNumber !== item.installmentNumber,
      )
      setInstallmentsChecked(filteredInstallments)
    } else {
      setInstallmentsChecked([...installmentsChecked, item])
    }
  }

  const renderInstallmentsList = installmentsData.map((installment, index) => {
    const key = `installments-${index}`

    return (
      <InterUICheckBox
        checked={isSelected(installment)}
        variant="border-bottom"
        key={key}
        id={key}
        name={key}
        data-testid={key}
        value={installment.installmentNumber}
        onChange={() => selectInstallments(installment)}
      >
        <Div display="flex" alignItems="center" gap="16px">
          {iconDeposit}

          <Div>
            <PSmall marginBottom="4px" bold>
              {installment.description}
            </PSmall>
            <PSmall marginBottom="4px" bold>
              {formatToBRL(installment.value)}
            </PSmall>
          </Div>
        </Div>
      </InterUICheckBox>
    )
  })

  return (
    <>
      <H3 margin="24px 0 10px">Selecione as parcelas que deseja quitar</H3>
      {renderInstallmentsList}
    </>
  )
}
