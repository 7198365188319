import { IconType } from 'src/common/icons/types'
import { ReceiptStatusEnum } from 'src/enums/ReceiptStatus'

import { SagaWithHistory } from '../utils/providerSaga'

export enum HiringTypes {
  GET_HOME_CALL_REQUEST = `@hiring/GET_HOME_CALL_REQUEST`,
  GET_HOME = `@hiring/GET_HOME`,
  SET_HOME = `@hiring/SET_HOME`,
  GET_SUBPRODUCT_REQUEST = `@hiring/GET_SUBPRODUCT_REQUEST`,
  GET_SUBPRODUCT = `@hiring/GET_SUBPRODUCT`,
  SET_SUBPRODUCT = `@hiring/SET_SUBPRODUCT`,
  GET_SELECTED_MODALITY = `@hiring/GET_SELECTED_MODALITY`,
  SET_SELECTED_MODALITY = `@hiring/SET_SELECTED_MODALITY`,
  GET_SELECTED_VALUE = `@hiring/GET_SELECTED_VALUE`,
  SET_SELECTED_VALUE = `@hiring/SET_SELECTED_VALUE`,
  GET_CARDS_REQUEST = `@hiring/GET_CARDS_REQUEST`,
  GET_CARDS = `@hiring/GET_CARDS`,
  SET_CARDS = `@hiring/SET_CARDS`,
  GET_CHECKOUT_REQUEST = `@hiring/GET_CHECKOUT_REQUEST`,
  GET_CHECKOUT = `@hiring/GET_CHECKOUT`,
  SET_CHECKOUT = `@hiring/SET_CHECKOUT`,
  GET_PROPOSAL_REQUEST = `@hiring/GET_PROPOSAL_REQUEST`,
  GET_PROPOSAL = `@hiring/GET_PROPOSAL`,
  SET_PROPOSAL = `@hiring/SET_PROPOSAL`,
  GET_RECEIPT_REQUEST = `@hiring/GET_RECEIPT_REQUEST`,
  GET_RECEIPT = `@hiring/GET_RECEIPT`,
  SET_RECEIPT = `@hiring/SET_RECEIPT`,
  GET_CHECKED_CONSORTIUM_INSURANCE = `@hiring/GET_CHECKED_CONSORTIUM_INSURANCE`,
  SET_CHECKED_CONSORTIUM_INSURANCE = `@hiring/SET_CHECKED_CONSORTIUM_INSURANCE`,
  GET_PLAN = `@hiring/GET_PLAN`,
  SET_PLAN = `@hiring/SET_PLAN`,
}

export interface IHome {
  mainTitle: string
  mainSubtitle: string
  hasActiveConsortium: boolean
  banners: IBanner[]
  modalities: IModality[]
}

export interface ISubProductRequest extends SagaWithHistory {
  subProductId: string
}

export interface IModality {
  displayOrder: number
  productId: number
  subProductId?: number
  product: string
  productDetailTitle: string
  productDetailSubTitle: string
  imageUrl: string
  offer: boolean
  isVip: boolean
  installment: {
    min: number
    max: number
  }
  totalValue: {
    min: number
    max: number
  }
}

export interface IBanner {
  url: string
  route: string
  order: number
  title: string
}

export interface ILettersCreditRequest extends SagaWithHistory {
  productId?: number
  subProductId?: number
  installmentAmount?: number
  totalAmount?: number
  highlights?: boolean
  biggestPrice?: boolean
  lowestPrice?: boolean
  isVip?: boolean
}

export interface ICardsResponse {
  mainTitle: string
  mainSubtitle: string
  cards: ICard[]
}

export interface ICard {
  offer: boolean
  cardId: number
  cardTitle: string
  consortiumValue: number
  installmentValue: number
  term: number
  group: number
  highlightCard: boolean
  isVip: boolean
  productId: number
}

export interface IPlan {
  cardId: number
  salesType: number
  salesPlanId: number
  title: string
  subtitle?: string
  installments: string
  firstInstallments?: string
  amount: string
  offer: boolean
  totalAmount: number
  select: boolean
}

export interface IInsurance {
  title: string
  subtitle: string
  amount: number
  select: boolean
}

export interface IBottomSheetPlan {
  title: string
  description: string
}

export interface ICheckout {
  icon: IconType
  category: string
  consortiumValue: number
  installmentNumber: number
  billingDate: string
  reserveFundFee: number
  administrationFee: number
  group: number
  plan: {
    title: string
    plans: IPlan[]
  }
  insurance?: IInsurance
  proposalUuid: string
  bottomSheet?: IBottomSheetPlan[]
  isVip: boolean
  productId?: number
}

export interface ICheckoutRequest extends SagaWithHistory {
  cardId: number
  productId?: number
  subProductId?: number
  group: number
  installmentValue: number
  isVip: boolean
}

export interface IProposalRequest extends SagaWithHistory {
  insurance: string
  planId: string
  consortiumId: string
  installmentValue: string
  typeSaleGroup: string
}

export interface IProposalServiceRequest extends IProposalRequest {
  bankAccount: string
}

export interface IProposalResponse {
  proposalUrl: string
}

export interface ISelectedValue {
  value: number
}

export interface IReceipt {
  status: ReceiptStatusEnum
  sectionHeader: {
    title: string
    subtitle: string
  }
  sectionBody: {
    title: string
    group: number
    amount: number
    cardValue: number
    cardInstallment: number
    planInstallment: number
    dateRequest: string
    datePayment: string
    paymentType: string
  }
}

export interface IReceiptPaymentRequest extends SagaWithHistory {
  proposalUuid: string
}

export interface IPlanSelected {
  salesPlanId: number
  salesType: number
  plan: string
  totalAmount: number
  offer: boolean
}

export interface HiringState {
  readonly home: IHome
  readonly selectedModality: IModality
  readonly selectedValue: number
  readonly cards: ICardsResponse
  readonly checkout: ICheckout
  readonly proposal: IProposalResponse
  readonly receipt: IReceipt
  readonly checkedConsortiumInsurance: boolean
  readonly plan: IPlanSelected
}
