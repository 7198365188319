import { IModality } from 'src/store/ducks/hiring/types'
import { PMedium, PSmall } from 'src/styles/commons'

import { InterUIBox, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { Figure, Image, InformationContent } from './Item.styles'

interface IItemProps extends IModality {
  selectedItem: (modalities: IModality) => void
}

export const Item = ({
  selectedItem,
  product,
  imageUrl,
  offer,
  installment,
  ...props
}: IItemProps) => (
  <InterUIBox
    direction="column"
    padding="4px"
    onClick={() => selectedItem({ product, imageUrl, offer, installment, ...props })}
    id={product}
    data-testid={`test_modality${product}`}
  >
    <Figure>
      <Image src={imageUrl} alt={`Imagem ${product}`} />
      {offer && (
        <InterUITag style={{ position: 'absolute', left: '4px', bottom: '4px' }} variant="primary">
          Oferta
        </InterUITag>
      )}
    </Figure>
    <InformationContent>
      <PMedium margin="0px 0px 6px 4px" wordBreak="break-all" bold>
        {product}
      </PMedium>
      <PSmall margin="0px 0px 0px 4px">A partir de</PSmall>
      <PMedium margin="0px 0px 4px 4px" bold>
        {formatToBRL(installment.min)} / mês
      </PMedium>
    </InformationContent>
  </InterUIBox>
)
