import { ISummaryListVariants } from 'src/Interfaces/ISummaryListVariants'
import { SummaryListVariantsEnum } from 'src/enums/SummaryListVariants'
import { H3, P } from 'src/styles/commons'
import { SIZE_SM } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIList } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

export interface ISummaryItem {
  id: number
  text: string
  value: string
  color?: string
  icon?: string
  skipMargin?: boolean
}
export interface ISummaryList {
  items: ISummaryItem[]
  variant?: ISummaryListVariants
  title?: string
  margin?: string
  handleClick?: (item: string) => void
}

export const SummaryList = ({
  items,
  variant = SummaryListVariantsEnum.PS,
  margin = '8px 0px 0px',
  title,
  handleClick,
}: ISummaryList) => {
  const theme = useTheme()

  const chooseFontSize: { [status: string]: string } = {
    PXS: '0.625rem',
    PS: '0.75rem',
    PM: '0.875rem',
    PL: '1rem',
    PXL: '1.5rem',
  }

  const chooseLineHeight: { [status: string]: string } = {
    PXS: '0.75rem',
    PS: '0.9375rem',
    PM: '1.063rem',
    PL: '1.188rem',
    PXL: '1.188rem',
  }

  const summaryList = items?.map((item, index) => (
    <InterUIList
      key={uuid()}
      data-testid={`test-summary-${index}`}
      variant="horizontal"
      margin={item.skipMargin ? undefined : margin}
      text={
        <>
          <P
            fontSize={chooseFontSize[variant]}
            lineHeight={chooseLineHeight[variant]}
            scale={400}
            marginBottom="0px"
          >
            {item.text}
          </P>
          {item.icon && handleClick && (
            <Icons.Help
              width={SIZE_SM}
              height={SIZE_SM}
              color={theme.colors.primary.A500}
              onClick={() => handleClick(item.icon as string)}
              style={{ padding: '2px 0 0 4px' }}
            />
          )}
        </>
      }
      value={
        <P
          fontSize={chooseFontSize[variant]}
          lineHeight={chooseLineHeight[variant]}
          color={item.color}
          marginBottom="0px"
          bold
        >
          {item.value}
        </P>
      }
    />
  ))

  return (
    <>
      {title && <H3>{title}</H3>}
      {summaryList}
    </>
  )
}
