import { AnyAction } from 'redux'
import { IExtract, IExtractTransaction } from 'src/Interfaces/afterSales'
import BaseBridge from 'src/config/bridge/BaseBridge'
import AfterSalesService from 'src/services/AfterSalesService'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { CheckoutActions } from '../checkout/actions'
import { IFormOfPayment } from '../checkout/types'
import { HiringActions } from '../hiring/actions'
import { IProposalResponse } from '../hiring/types'
import { AfterSalesActions } from './actions'
import { AfterSalesTypes } from './types'

function* getMyConsortia() {
  try {
    yield* put(LoadingActions.show())

    yield* put(AfterSalesActions.setSelectedConsortiumId(''))

    BaseBridge.requestAnalytics('C_FRONT_ISCON_MY_CONSORTIA_BEFORE', {
      file: 'afterSales/saga',
      action: 'Antes de chamar AfterSalesService.getMyConsortia',
    })
    const myConsortiaResponse = yield* call(AfterSalesService.getMyConsortia)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_MY_CONSORTIA_AFTER', {
      file: 'afterSales/saga',
      action: 'Response do AfterSalesService.getMyConsortia',
      response: JSON.stringify(myConsortiaResponse.object),
    })

    yield* put(AfterSalesActions.setMyConsortia(myConsortiaResponse.object))
    yield* put(HiringActions.setProposal({} as IProposalResponse))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AfterSalesActions.getMyConsortia')
  }
}

function* getConsortiumDetails({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CONSORTIUM_DETAILS_BEFORE', {
      file: 'afterSales/saga',
      action: 'Antes de chamar AfterSalesService.getConsortiumDetails',
      request: JSON.stringify(payload),
    })
    const consortiumDetailsResponse = yield* call(
      AfterSalesService.getConsortiumDetails,
      userInfoBridge.account,
      payload.consortiumId,
      payload.status,
    )

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CONSORTIUM_DETAILS_AFTER', {
      file: 'afterSales/saga',
      action: 'Response do AfterSalesService.getConsortiumDetails',
      response: JSON.stringify(consortiumDetailsResponse.object),
    })

    yield* put(AfterSalesActions.setConsortiumDetails(consortiumDetailsResponse.object))
    yield* put(AfterSalesActions.setSelectedConsortiumId(payload.consortiumId))

    const proposalUrl = consortiumDetailsResponse.object?.quickAcessBar?.proposalUrl

    if (proposalUrl) {
      yield* put(HiringActions.setProposal({ proposalUrl }))
    }

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AfterSalesActions.getConsortiumExtract')
  }
}

function* getConsortiumExtract({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const consortiumId = yield* select(AfterSalesActions.getSelectedConsortiumId)
    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_EXTRACT_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar AfterSalesService.getInstallmentsExtract',
      request: JSON.stringify(payload),
    })
    const extractResponse = yield* call(
      AfterSalesService.getInstallmentsExtract,
      userInfoBridge.account,
      consortiumId.toString(),
    )

    BaseBridge.requestAnalytics('C_FRONT_ISCON_EXTRACT_AFTER', {
      file: 'afterSales/saga',
      action: 'Response do AfterSalesService.getInstallmentsExtract',
      consortiumId: consortiumId.toString(),
      response: JSON.stringify(extractResponse.object),
    })

    yield* put(CheckoutActions.setFormOfPayment({} as IFormOfPayment))

    yield* put(AfterSalesActions.setConsortiumExtract(extractResponse.object))

    const lateInstallments = filterLateInstallments(extractResponse.object)

    yield* put(AfterSalesActions.setLateInstallments(lateInstallments))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AfterSalesActions.getConsortiumExtract')
  }
}

function* getPaidInstallments({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { consortiumId, installmentNumber } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_PAID_INSTALLMENTS_BEFORE', {
      file: 'afterSales/saga',
      action: 'Antes de chamar AfterSalesService.getPaidInstallments',
      request: JSON.stringify(payload),
    })
    const response = yield* call(
      AfterSalesService.getPaidInstallments,
      userInfoBridge.account,
      consortiumId,
      installmentNumber,
    )

    BaseBridge.requestAnalytics('C_FRONT_ISCON_PAID_INSTALLMENTS_AFTER', {
      file: 'afterSales/saga',
      action: 'Response do AfterSalesService.getPaidInstallments',
      response: JSON.stringify(response.object),
    })

    yield* put(AfterSalesActions.setPaidInstallments(response.object))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AfterSalesActions.getConsortiumExtract')
  }
}

function filterLateInstallments(extractList: IExtract[]) {
  let lateInstallments: IExtractTransaction[] = []

  if (!Array.isArray(extractList) || extractList.length === 0) return []

  extractList.forEach((extract) => {
    const { transactions } = extract

    const lateTransactions = transactions?.filter(
      (transaction) => transaction.status.toLocaleLowerCase() === 'pagamento atrasado',
    )

    lateInstallments = [...lateInstallments, ...lateTransactions]
  })
  return lateInstallments
}

export default function* watchAfterSales() {
  yield* takeLatest(AfterSalesTypes.GET_MY_CONSORTIA_REQUEST, getMyConsortia)
  yield* takeLatest(AfterSalesTypes.GET_CONSORTIUM_DETAILS_REQUEST, getConsortiumDetails)
  yield* takeLatest(AfterSalesTypes.GET_CONSORTIUM_EXTRACT_REQUEST, getConsortiumExtract)
  yield* takeLatest(AfterSalesTypes.GET_PAID_INSTALLMENTS_REQUEST, getPaidInstallments)
}
