import { InterUIContainer, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'
import { LoadModality } from '../components/LoadModality/LoadModality'

export const LoadHome = () => (
  <SkeletonContent data-testid="loading-home">
    <InterUIContainer margin="24px 24px 32px">
      <SkeletonContent>
        <InterUISkeleton height="60px" marginBottom="24px" />
        <InterUISkeleton height="60px" marginBottom="8px" />
        <InterUISkeleton height="34px" marginBottom="0" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUIContainer margin="0 24px">
      <SkeletonGrid>
        <LoadModality qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadModality qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadModality qtd={2} />
      </SkeletonGrid>

      <SkeletonGrid>
        <LoadModality qtd={2} />
      </SkeletonGrid>
    </InterUIContainer>
  </SkeletonContent>
)
