import { Dispatch, SetStateAction } from 'react'

import { ICheckoutBS } from 'src/Interfaces/ICheckout'
import { Button } from 'src/components'
import { PMedium } from 'src/styles/commons'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'

interface CheckoutBSProps {
  choseLabel: ICheckoutBS
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
}

interface IDataBS {
  title: string
  description: string
}

export const CheckoutBS = ({ choseLabel, toggleState }: CheckoutBSProps) => {
  const [toggle, setToggle] = toggleState

  const reserveFund: IDataBS = {
    title: 'Taxa de fundo de reserva',
    description:
      'É um valor percentual cobrado nas parcelas do consórcio para garantir que as contemplações aconteçam mesmo em caso de inadimplência.',
  }

  const administrationFee: IDataBS = {
    title: 'Taxa de administração',
    description:
      'É a remuneração da administradora do fundo pelo serviço prestado da contratação à contemplação da carta de crédito.',
  }

  const group: IDataBS = {
    title: 'Grupo',
    description:
      'O grupo de consórcio é um conjunto de pessoas unidas pelo mesmo objetivo, que é o de adquirir determinado bem ou serviço.',
  }

  const chooseStatus: { [choseLabel: string]: IDataBS } = {
    TX_RESERVE: reserveFund,
    TX_ADMIN: administrationFee,
    GROUP: group,
  }

  return (
    <InterUIBottomSheet
      data-testid="summary-bottom-sheet"
      toggle={toggle}
      onHide={() => setToggle(false)}
      title={chooseStatus[choseLabel].title}
    >
      <PMedium scale={400} marginBottom="24px">
        {chooseStatus[choseLabel].description}
      </PMedium>
      <Button variant="primary" onClick={() => setToggle(false)}>
        Entendi
      </Button>
    </InterUIBottomSheet>
  )
}
