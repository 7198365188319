import { InterUIContainer, InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent } from '../Skeleton.styles'
import { LoadPlans } from '../components/LoadPlans/LoadPlans'
import { LoadSummaryList } from '../components/LoadSummaryList/LoadSummaryList'

export const LoadCheckout = () => (
  <SkeletonContent data-testid="loading-checkout">
    <InterUIContainer>
      <SkeletonContent display="flex" flexDirection="column" alignItems="center">
        <InterUISkeleton variant="circle" height="64px" width="64px" marginBottom="8px" />
        <InterUISkeleton height="16px" width="20%" marginBottom="16px" />
        <InterUISkeleton height="24px" width="40%" marginBottom="0px" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUIContainer margin="0 24px">
      <SkeletonContent>
        <InterUISkeleton height="16px" width="20%" marginBottom="16px" />
      </SkeletonContent>
      <LoadSummaryList qtd={5} />
      <SkeletonContent>
        <InterUISkeleton height="16px" width="70%" marginBottom="16px" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUIContainer>
      <InterUISeparator height="8px" margin="0px" />
    </InterUIContainer>

    <InterUIContainer margin="0px 24px">
      <SkeletonContent>
        <InterUISkeleton height="16px" width="100%" marginBottom="16px" />
      </SkeletonContent>
    </InterUIContainer>

    <InterUIContainer margin="0px 24px">
      <LoadPlans qtd={3} />
    </InterUIContainer>

    <InterUIContainer margin="0px 24px">
      <InterUISkeleton height="16px" width="80%" marginBottom="8px" />
    </InterUIContainer>

    <InterUIContainer>
      <InterUISeparator height="8px" margin="0px" />
    </InterUIContainer>
  </SkeletonContent>
)
