/**
 * Títulos disponíveis na aplicação.
 */
export enum PageTitles {
  EMPTY = '',
  CONSORTIUM = 'Consórcio',
  CONSORTIA = 'Consórcios',
  MY_CONSORTIA = 'Meus Consórcios',
  EXTRACT = 'Extrato de parcelas',
  PROPOSAL = 'Proposta',
  PENDING = 'Pagar parcela pendente',
  RECEIPT = 'Comprovante',
}
