export enum ErrorTypes {
  SHOW = `@error/SHOW`,
  HIDE = `@error/HIDE`,
  SET_ERROR_DETAILS = `@error/SET_ERROR_DETAILS`,
  GET_ERROR_DETAILS = `@error/GET_ERROR_DETAILS`,
}

export interface ErrorDetails {
  title: string | JSX.Element
  subTitle: string | JSX.Element
  route?: string
  nameButton?: string
  icon?: string
  disabledButton?: boolean
  disabledHome?: boolean
}

export interface ErrorState {
  readonly toggle: boolean
  readonly details: ErrorDetails
}
