import styled from 'styled-components'

export const Figure = styled.figure`
  position: relative;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const ImageBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 41px;
  bottom: -1px;
  background: ${(props) => props.theme.colors.theme};
  border-radius: 24px 24px 0px 0px;
`
