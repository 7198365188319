import { History } from 'history'
import BaseBridge from 'src/config/bridge/BaseBridge'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { MockService } from 'src/services/mockService'
import { put } from 'typed-redux-saga'

import { ErrorActions } from '../error/actions'
import { ErrorDetails } from '../error/types'
import { LoadingActions } from '../loading/actions'

const IS_NOT_RUNNING_PROD =
  process.env.REACT_APP_INTER_ENV && process.env.REACT_APP_INTER_ENV.toLowerCase() !== 'production'
export interface SagaWithHistory {
  history?: History
  pathname?: string
  goBack?: boolean
}

/**
 * Realiza o redirecionamento utilizando o useHistory recebido por parâmetro.
 * @param payload Payload recebido através da action.
 */
export function redirectThroughSaga(payload: SagaWithHistory): void {
  if (payload && payload.history && (payload.pathname || payload.goBack)) {
    const { history } = payload

    if (payload.goBack) {
      history.goBack()
    } else if (payload?.pathname) {
      history.push(payload.pathname)
    }
  }
}

/**
 * Tratativa de erro a ser realizada no Saga.
 */
export function* errorHandlingSaga(
  error: Error,
  errorCodeRef: string,
  errorDetails?: ErrorDetails,
  restart?: boolean,
) {
  NewRelicUtils.noticeError(error, { errorCodeRef })

  if (
    MockService.shouldMock &&
    !MockService.apiKey &&
    BaseBridge.isBrowser() &&
    IS_NOT_RUNNING_PROD
  ) {
    errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle: 'O parâmetro mockApiKey não foi informado, por favor verifique e tente novamente.',
      disabledButton: true,
    }
  }

  if (errorDetails === undefined) {
    errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Esse serviço está temporariamente indisponível. Você pode tentar novamente mais tarde.',
      disabledButton: false,
      route: restart ? TypesRoutes.START : undefined,
    }
  }

  yield* put(ErrorActions.show(errorDetails))

  yield* put(LoadingActions.hide())
}
