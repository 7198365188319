import styled from 'styled-components'

export const ContentPayment = styled.div`
  &.disabled {
    > div {
      color: ${(props) => props.theme.colors.neutral.grayscale.A300};
      background-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
      pointer-events: none;
      border: none;
    }

    svg {
      rect,
      circle,
      path {
        stroke: ${(props) => props.theme.colors.neutral.grayscale.A300};
      }
    }
  }
`
