import styled from 'styled-components'

export const BtnRight = styled.button.attrs({ type: 'button' })`
  justify-content: flex-end;
`
export const ContentNavbar = styled.div`
  nav {
    height: auto;
  }
`
