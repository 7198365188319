import { action } from 'typesafe-actions'

import { TabTypes } from './types'
import { RootState } from '../..'

const get = (state: RootState) => state.tab.selectedTab

const installment = () => action(TabTypes.INSTALLMENT)

const amount = () => action(TabTypes.AMOUNT)

export const TabActions = {
  get,
  installment,
  amount,
}
