import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { NewRelicTypes } from './types'

const getUserContext = (state: RootState) => state.newRelic.context

const setUserContext = (data: string) => action(NewRelicTypes.SET_USER_CONTEXT, data)

const getUserSegmentation = (state: RootState) => state.newRelic.segmentation

const setUserSegmentation = (data: string) => action(NewRelicTypes.SET_USER_SEGMENTATION, data)

const getUserAppVersion = (state: RootState) => state.newRelic.appVersion

const setUserAppVersion = (data: string) => action(NewRelicTypes.SET_USER_APP_VERSION, data)

export const NewRelicActions = {
  getUserContext,
  setUserContext,
  getUserSegmentation,
  setUserSegmentation,
  getUserAppVersion,
  setUserAppVersion,
}
