import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { getInputSizeByText, numberFormat } from 'src/utils/commons'
import { PMedium, PSmall } from 'src/styles/commons'
import { useDispatch } from 'react-redux'
import { TabActions } from 'src/store/ducks/tab/actions'
import { useTheme } from 'styled-components'
import { PreSimulateEnum } from 'src/enums/PreSimulate'

import { currencyMask, InterUIInputGroup, InterUISlider } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ContentPricing, PrefixPricing } from './SimulationSelector.styles'

interface SimulationProps {
  type: PreSimulateEnum
  initialValue: number
  min: number
  max: number
  selectedSimulationValue: (value: number) => void
}

export const SimulationSelector = ({
  type,
  initialValue,
  min,
  max,
  selectedSimulationValue,
}: SimulationProps) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const maxInputLength = numberFormat(max).length
  const inputElEnabled = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState(initialValue)
  const [width, setWidth] = useState('')
  const [feedBackError, setFeedBackError] = useState(false)

  useEffect(() => {
    type === PreSimulateEnum.INSTALLMENT
      ? dispatch(TabActions.installment())
      : dispatch(TabActions.amount())
  }, [])

  useLayoutEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useLayoutEffect(() => {
    selectedSimulationValue(value)

    const formatValue = numberFormat(value)
    setWidth(getInputSizeByText(formatValue))

    if (value < min || value > max) {
      setFeedBackError(true)
    } else {
      setFeedBackError(false)
    }
  }, [value])

  /**
   * Lida com a mudança do Slider
   * Seta o Value com o valor recebido do range.
   * Seta a largura do Input.
   * @param target propriedade do elemento input informado.
   */
  const handleSliderChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const formatValue = Number(target.value)
    setValue(formatValue)

    const valueWithCents = `${formatValue},00`
    setWidth(getInputSizeByText(valueWithCents))
  }

  /**
   * Lida com a mudança do Input.
   * Aplica a máscara monetária no elemento input informado.
   * Seta o Value passando os dígitos inseridos no Input.
   * Seta a largura do Input.
   * @param target propriedade do elemento input informado.
   */
  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const onlyDigits = Number(target.value.replace(/[^\d]+/g, ''))

    if (inputElEnabled.current) {
      currencyMask(inputElEnabled.current, target.value)
    }

    setValue(onlyDigits / 100)

    setWidth(getInputSizeByText(target.value))
  }

  return (
    <>
      <PMedium marginBottom="0px" scale={400} textAlign="center">
        {type === PreSimulateEnum.INSTALLMENT
          ? 'Escolha o valor da parcela:'
          : 'Escolha o valor total do consórcio:'}
      </PMedium>
      <InterUIInputGroup style={{ height: '50px' }} variant="pricing">
        <ContentPricing>
          <PrefixPricing>R$ </PrefixPricing>
          <input
            type="text"
            inputMode="numeric"
            maxLength={maxInputLength}
            name="input-pricing"
            id="input-pricing"
            data-testid="test-input-pricing"
            value={numberFormat(value)}
            ref={inputElEnabled}
            onChange={handleInputChange}
            placeholder="0,00"
            autoComplete="off"
            style={{ width }}
          />
        </ContentPricing>
        {feedBackError && (
          <PSmall
            margin="0px"
            color={theme.colors.feedback.error.A500}
            textAlign="center"
            data-testid="test-feedback-error"
          >
            Valor {value > max ? `máximo: ${formatToBRL(max)}` : `mínimo: ${formatToBRL(min)}`}
          </PSmall>
        )}
      </InterUIInputGroup>
      <InterUISlider
        id="inter-ui-slider"
        min={min}
        max={max}
        value={value}
        onChange={handleSliderChange}
        leftText={
          <PSmall scale={400} marginBottom="0px">
            {formatToBRL(min)}
          </PSmall>
        }
        rightText={
          <PSmall scale={400} marginBottom="0px">
            {formatToBRL(max)}
          </PSmall>
        }
        variant="between"
      />
    </>
  )
}
