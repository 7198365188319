import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { UrlTypes } from './types'

const get = (state: RootState) => state.url.selectedUrl

const faq = () => action(UrlTypes.FAQ)

const knowMore = () => action(UrlTypes.KNOW_MORE)

export const UrlActions = {
  get,
  faq,
  knowMore,
}
