import { useEffect, useLayoutEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components/Button/Button'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/PageTitles'
import { PreSimulateEnum } from 'src/enums/PreSimulate'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { UrlActions } from 'src/store/ducks/aboutUrl/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { H1, PMedium } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'

import {
  InterUIBottomSheet,
  InterUIContainer,
  InterUISegmentControl,
} from '@interco/inter-ui-react-lib'

import { Figure, Image, ImageBottom } from './PreSimulate.styles'
import { SimulationSelector } from './SimulationSelector/SimulationSelector'

export const PreSimulate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const account_context = useSelector(NewRelicActions.getUserContext)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)

  const selectedTab = useSelector(TabActions.get)
  const selectedModality = useSelector(HiringActions.getSelectedModality)
  const isLoading = useSelector(LoadingActions.get)

  const MIN_INSTALLMENT = selectedModality.installment?.min
  const MAX_INSTALLMENT = selectedModality.installment?.max
  const MIN_AMOUNT = selectedModality.totalValue?.min
  const MAX_AMOUNT = selectedModality.totalValue?.max

  const [installmentValue, setInstallmentValue] = useState<number>(MIN_INSTALLMENT)
  const [amountValue, setAmountValue] = useState<number>(MIN_AMOUNT)
  const [bottomSheet, setBottomSheet] = useState(false)

  const handleBackButton = () => {
    if (accessViaDeepLink) {
      dispatch(PathParamsActions.setAccessedViaDeepLink(false))
      history.replace(TypesRoutes.START)
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: handleBackButton,
  })

  useEffect(() => {
    dispatch(UrlActions.faq())
  }, [])

  useLayoutEffect(() => {
    if (selectedTab === PreSimulateEnum.INSTALLMENT) {
      setInstallmentValue(MIN_INSTALLMENT)
    } else {
      setAmountValue(MIN_AMOUNT)
    }
  }, [selectedTab])

  /**
   * Lida com o evento de click do botão "Simular".
   * Caso o valor da simulação esteja fora do range exibe o BottomSheet.
   * Caso o valor da simulação esteja dentro do range exibe o valor da simulação.
   */
  const handleClickSimulate = () => {
    switch (selectedTab) {
      case PreSimulateEnum.INSTALLMENT:
        if (installmentValue > MAX_INSTALLMENT || installmentValue < MIN_INSTALLMENT) {
          setBottomSheet(true)
        } else {
          BaseBridge.requestAnalytics(TAGS.CONSOR_FLUXO.screen, {
            ...TAGS.CONSOR_FLUXO,
            account_context,
            segment,
            ref_figma: '4',
            action_id: 'Simular',
            Type: selectedTab,
            Value: installmentValue.toString(),
          })

          dispatch(
            HiringActions.getCardsRequest({
              history,
              pathname: TypesRoutes.LETTERS_CREDIT,
              productId: selectedModality.productId,
              subProductId: selectedModality.subProductId,
              installmentAmount: installmentValue,
            }),
          )
        }
        break
      case PreSimulateEnum.AMOUNT:
        if (amountValue > MAX_AMOUNT || amountValue < MIN_AMOUNT) {
          setBottomSheet(true)
        } else {
          dispatch(
            HiringActions.getCardsRequest({
              history,
              pathname: TypesRoutes.LETTERS_CREDIT,
              productId: selectedModality.productId,
              totalAmount: amountValue,
            }),
          )
        }
        break
      default:
        break
    }
  }

  const handleClickAllSimulate = () => {
    BaseBridge.requestAnalytics(TAGS.CONSOR_FLUXO.screen, {
      ...TAGS.CONSOR_FLUXO,
      account_context,
      segment,
      ref_figma: '4',
      action_id: 'Ver todos',
      Type: selectedTab,
    })
    dispatch(
      HiringActions.getCardsRequest({
        history,
        pathname: TypesRoutes.LETTERS_CREDIT,
        productId: selectedModality.productId,
        lowestPrice: true,
      }),
    )
  }

  /**
   * Lida com a ocultação do BottomSheet.
   * Caso o valor da simulação seja menor que o permitido o valor será ajustado para o mínimo.
   * Caso o valor da simulação seja maior que o permitido o valor será ajustado para o máximo.
   */
  const handleBottomSheetHiding = () => {
    switch (selectedTab) {
      case PreSimulateEnum.INSTALLMENT:
        if (installmentValue > MAX_INSTALLMENT) {
          setInstallmentValue(MAX_INSTALLMENT)
          setBottomSheet(false)
        } else if (installmentValue < MIN_INSTALLMENT) {
          setInstallmentValue(MIN_INSTALLMENT)
          setBottomSheet(false)
        }
        break
      case PreSimulateEnum.AMOUNT:
        if (amountValue > MAX_AMOUNT) {
          setAmountValue(MAX_AMOUNT)
          setBottomSheet(false)
        } else if (amountValue < MIN_AMOUNT) {
          setAmountValue(MIN_AMOUNT)
          setBottomSheet(false)
        }
        break
      default:
        break
    }
  }

  /**
   * Renderiza o rodapé da página.
   */
  const stickyFooter = (
    <InterUIContainer margin="8px 24px 24px">
      <Button
        margin="0 0 8px"
        onClick={handleClickSimulate}
        data-testid="test-button-simulate"
        disabled={isLoading}
      >
        Simular
      </Button>
      <Button variant="secondary" onClick={handleClickAllSimulate} disabled={isLoading}>
        Ver todos os consórcios
      </Button>
    </InterUIContainer>
  )

  return (
    <>
      {Object.keys(selectedModality).length !== 0 && (
        <InterUIContainer margin="0px" stickyFooter={stickyFooter}>
          <InterUIContainer margin="0px">
            <Figure>
              <Image src={selectedModality.imageUrl} alt={selectedModality.product} />
              <ImageBottom />
            </Figure>
          </InterUIContainer>
          <InterUIContainer margin="0px 24px 0px 24px">
            <H1>{selectedModality.productDetailTitle}</H1>
            <PMedium margin="8px 0px 0px" scale={400}>
              {selectedModality.productDetailSubTitle}
            </PMedium>
            <InterUISegmentControl
              selectedLabel={selectedTab}
              variant="secondary"
              margin="14px 0px 10px"
            >
              <SimulationSelector
                type={PreSimulateEnum.INSTALLMENT}
                data-label={PreSimulateEnum.INSTALLMENT}
                initialValue={installmentValue}
                min={MIN_INSTALLMENT}
                max={MAX_INSTALLMENT}
                selectedSimulationValue={(value) => setInstallmentValue(value)}
              />

              <SimulationSelector
                type={PreSimulateEnum.AMOUNT}
                data-label={PreSimulateEnum.AMOUNT}
                initialValue={amountValue}
                min={MIN_AMOUNT}
                max={MAX_AMOUNT}
                selectedSimulationValue={(value) => setAmountValue(value)}
              />
            </InterUISegmentControl>
          </InterUIContainer>
          <InterUIBottomSheet
            title="Atenção!"
            toggle={bottomSheet}
            onHide={handleBottomSheetHiding}
            data-testid="bs-invalid-value"
          >
            {selectedTab === PreSimulateEnum.INSTALLMENT ? (
              <PMedium margin="4px 0 44px" scale={400}>
                O valor da parcela que você informou R$ {numberFormat(installmentValue)} é{' '}
                {installmentValue > MAX_INSTALLMENT ? 'maior' : 'menor'} que o permitido. O valor
                será ajustado para o valor{' '}
                {installmentValue > MAX_INSTALLMENT ? 'máximo' : 'mínimo'}.
              </PMedium>
            ) : (
              <PMedium margin="4px 0 44px" scale={400}>
                O valor do consórcio que você informou R$ {numberFormat(amountValue)} é{' '}
                {amountValue > MAX_AMOUNT ? 'maior' : 'menor'} que o permitido. O valor será
                ajustado para o valor {amountValue > MAX_AMOUNT ? 'máximo' : 'mínimo'}.
              </PMedium>
            )}
            <Button data-testid="btn-bottom-sheet" onClick={handleBottomSheetHiding}>
              Entendi
            </Button>
          </InterUIBottomSheet>
        </InterUIContainer>
      )}
    </>
  )
}
