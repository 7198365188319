import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Skeleton } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/PageTitles'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { ContainerFullPage } from 'src/styles/commons'
import { formatDateWithoutYear } from 'src/utils/commons'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ConsortiumData } from './ConsortiumData/ConsortiumData'
import { ReceiptStatus } from './ReceiptStatus/ReceiptStatus'

export const Receipt = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const { status, sectionBody } = useSelector(HiringActions.getReceipt)
  const { product } = useSelector(HiringActions.getSelectedModality)

  const consortiumData = {
    group: sectionBody?.group,
    amount: formatToBRL(sectionBody?.amount),
    cardValue: formatToBRL(sectionBody?.cardValue),
    planInstallment: `${sectionBody?.planInstallment} meses`,
    cardInstallment: sectionBody?.cardInstallment,
    dateRequest: formatDateWithoutYear(sectionBody?.dateRequest),
    datePayment: formatDateWithoutYear(sectionBody?.datePayment),
    paymentType: sectionBody?.paymentType,
  }

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    showHomeBtn: true,
    hideFaqBtn: true,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    status &&
      sectionBody &&
      BaseBridge.requestAnalytics(TAGS.C_CONSOR_COMPROVANTE_DC_ACESSO.screen, {
        ...TAGS.C_CONSOR_COMPROVANTE_DC_ACESSO,
        account_context,
        segment,
        status,
        'Valor da carta': `${consortiumData.cardValue}`,
        'Valor da Parcela': `${consortiumData.amount}`,
        'Payment method': `${consortiumData.paymentType}`,
        Modalidade: `${product}`,
        data: `${consortiumData.dateRequest}`,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      })
  }, [sectionBody])

  const handleHome = () => {
    dispatch(PathParamsActions.setAccessedViaDeepLink(false))
    history.push(TypesRoutes.START)
  }

  const stickyFooter = (
    <InterUIContainer margin="32px 24px">
      <Button variant="primary" margin="0 0 16px" onClick={handleHome}>
        Ir para a home
      </Button>
    </InterUIContainer>
  )
  return status && sectionBody ? (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <ReceiptStatus status={status} />
      <InterUISeparator />
      <ConsortiumData {...consortiumData} />
      <InterUISeparator />
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="0px" overflowHidden>
      <Skeleton.LoadReceipt />
    </ContainerFullPage>
  )
}
