import BaseService from 'src/config/bridge/BaseService'
import { IEligible } from 'src/store/ducks/validation/types'

import { MockService } from './mockService'

export default class ValidationService {
  static getEligible() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IEligible>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/validation/eligibility`,
      headers,
    })
  }
}
