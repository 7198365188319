export enum VideoUrlTypes {
  GET_VIDEO_URL_REQUEST = `@video/GET_VIDEO_URL_REQUEST`,
  GET_VIDEO_URL = `@video/GET_VIDEO_URL`,
  SET_VIDEO_URL = `@video/SET_VIDEO_URL`,
}

export interface IVideoResponse {
  url: string
}

export interface VideoUrlState {
  readonly videoUrl: IVideoResponse
}
