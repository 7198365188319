export enum IconType {
  HANDBAG = '@iconType/handbag',
  CAR = '@iconType/car',
  MOTORCYCLE = '@iconType/motorCycle',
  HOME = '@iconType/home',
  SMARTPHONES = '@iconType/smartphones',
  COMPUTER = '@iconType/computer',
  TRUCK = '@iconType/truck',
  SERVICES = '@iconType/services',
  NOT_FOUND = '@iconType/not_found',
}

export interface IconParams {
  color?: string
  width?: number
  height?: number
  action?: () => void
}
