import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { CheckoutTypes, IFormOfPayment } from './types'

const getFormOfPayment = (state: RootState) => state.checkout.formOfPayment

const setFormOfPayment = (data: IFormOfPayment) => action(CheckoutTypes.SET_FORM_OF_PAYMENT, data)

export const CheckoutActions = {
  getFormOfPayment,
  setFormOfPayment,
}
