export enum NewRelicTypes {
  GET_USER_CONTEXT = `@newRelic/GET_USER_CONTEXT`,
  SET_USER_CONTEXT = `@newRelic/SET_USER_CONTEXT`,
  GET_USER_SEGMENTATION = `@newRelic/GET_USER_SEGMENTATION`,
  SET_USER_SEGMENTATION = `@newRelic/SET_USER_SEGMENTATION`,
  GET_USER_APP_VERSION = `@newRelic/GET_USER_APP_VERSION`,
  SET_USER_APP_VERSION = `@newRelic/SET_USER_APP_VERSION`,
}

export interface NewRelicState {
  readonly context: string
  readonly segmentation: string
  readonly appVersion: string
}
