import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IQuickAcessBar } from 'src/Interfaces/afterSales'
import { QuickAccessBarEnum } from 'src/enums/QuickAccessBar'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { P, PXSmall } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { ActionsIcons, ContentIcons, GroupIcons, QtdExtract } from './QuickAccessBar.style'

interface IQuickAccessBar {
  id: number
  icon: JSX.Element
  description: string
  qtdExtracts?: number
}

interface IQuickAccessProps {
  quickAcessBar: IQuickAcessBar
}

export const QuickAccessBar = ({ quickAcessBar }: IQuickAccessProps) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const colorPrimary = theme.colors.primary.A500
  const colorTheme = theme.colors.theme

  const moreLinksIcons = {
    iconPdf: <Icons.PdfFile width={SIZE_MD} height={SIZE_MD} color={colorPrimary} />,
    iconReceipt: <Icons.Receipt width={SIZE_MD} height={SIZE_MD} color={colorPrimary} />,
  }

  const quickAccessBar: IQuickAccessBar[] = [
    {
      id: 1,
      icon: moreLinksIcons.iconReceipt,
      description: QuickAccessBarEnum.EXTRACT,
      qtdExtracts: quickAcessBar.paymentStatementQuantity,
    },
    {
      id: 2,
      icon: moreLinksIcons.iconPdf,
      description: QuickAccessBarEnum.PROPOSAL,
      qtdExtracts: 0,
    },
  ]

  const handleClickQuickAccessBar = (item: QuickAccessBarEnum) => {
    if (item === QuickAccessBarEnum.PROPOSAL) {
      history.push(TypesRoutes.PROPOSAL)
    } else {
      dispatch(
        AfterSalesActions.getConsortiumExtractRequest({
          history,
          pathname: TypesRoutes.EXTRACT,
        }),
      )
    }
  }

  const renderQuickAccessBar = quickAccessBar.map((item) => (
    <ActionsIcons
      key={item.id}
      data-testid={`test-links-${item.id}`}
      onClick={() => handleClickQuickAccessBar(item.description as QuickAccessBarEnum)}
    >
      <ContentIcons>{item.icon}</ContentIcons>
      <PXSmall bold margin="8px 0 0" textAlign="center">
        {item.description}
      </PXSmall>
      {item.qtdExtracts !== 0 && (
        <QtdExtract>
          <P marginBottom="0px" fontSize="14px" lineHeight="0px" color={colorTheme} bold>
            {item.qtdExtracts}
          </P>
        </QtdExtract>
      )}
    </ActionsIcons>
  ))

  return <GroupIcons>{renderQuickAccessBar}</GroupIcons>
}
