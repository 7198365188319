import React, { useCallback, useEffect, useState } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { FallbackError } from 'src/pages/shared/Error/FallbackError'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { NavbarActions } from 'src/store/ducks/navbar/actions'
import { scrollToTop } from 'src/utils/commons'

import { mixedRoutes, TypesRoutes } from './mixedRoutes/mixedRoutes'
import './transition-style.css'

const Routes: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const goTo = useSelector(NavbarActions.getDirection)

  const [direction, setDirection] = useState('pageSliderLeft')

  const scrollTop = () => {
    scrollToTop()
  }

  const redirectTo = '/'

  useEffect(() => {
    const checkPath = () => {
      const { pathname } = history.location
      if (pathname !== '/' && pathname !== redirectTo) {
        history.push(redirectTo)
      }
    }

    checkPath()
  }, [history, redirectTo])

  useEffect(() => {
    setDirection(goTo === 'backward' ? 'pageSliderRight' : 'pageSliderLeft')
  }, [goTo])

  const onErrorBoundary = useCallback(
    (error: Error, info: { componentStack: string }) => {
      NewRelicUtils.noticeError(error, {
        errorCodeRef: info.componentStack,
      })
      const errorDetails = {
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        route: TypesRoutes.START,
      }

      dispatch(ErrorActions.setErrorDetails(errorDetails))
    },
    [dispatch],
  )

  const goBackToHistory = useCallback(() => {
    history.goBack()
    history.go(-2)
  }, [history])

  return (
    <ErrorBoundary
      FallbackComponent={FallbackError}
      onReset={goBackToHistory}
      onError={onErrorBoundary}
    >
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              onEnter={scrollTop}
              timeout={500}
              key={location.key}
              classNames={direction}
            >
              <Switch location={location}>
                {mixedRoutes.map((page) => (
                  <Route
                    key={location.pathname}
                    path={page.route}
                    exact
                    component={page.component}
                  />
                ))}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </ErrorBoundary>
  )
}

export default withRouter(Routes)
