import Attention from '@interco/icons/orangeds/MD/attention'
import BookOpen from '@interco/icons/orangeds/MD/book-open'
import Canceled from '@interco/icons/orangeds/MD/canceled'
import Card from '@interco/icons/orangeds/MD/card'
import Check from '@interco/icons/orangeds/MD/check'
import CheckCircle from '@interco/icons/orangeds/MD/check-circle'
import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import ComputerCall from '@interco/icons/orangeds/MD/computer-call'
import Consortium from '@interco/icons/orangeds/MD/consortium'
import Credit from '@interco/icons/orangeds/MD/credit'
import Deposit from '@interco/icons/orangeds/MD/deposit-by-boleto'
import Dollar from '@interco/icons/orangeds/MD/dollar'
import Edit from '@interco/icons/orangeds/MD/edit'
import Email from '@interco/icons/orangeds/MD/email'
import Handbag from '@interco/icons/orangeds/MD/handbag'
import Help from '@interco/icons/orangeds/MD/help'
import Home from '@interco/icons/orangeds/MD/home'
import NoTaxes from '@interco/icons/orangeds/MD/no-taxes'
import NotFound from '@interco/icons/orangeds/MD/not-found'
import PdfFile from '@interco/icons/orangeds/MD/pdf-file'
import Pending from '@interco/icons/orangeds/MD/pending'
import Phone from '@interco/icons/orangeds/MD/phone'
import PhoneCall from '@interco/icons/orangeds/MD/phone-call'
import Profitability from '@interco/icons/orangeds/MD/profitability'
import Receipt from '@interco/icons/orangeds/MD/receipt'
import Tag from '@interco/icons/orangeds/MD/tag'
import Ticket from '@interco/icons/orangeds/MD/ticket'
import Wallet from '@interco/icons/orangeds/MD/wallet'
import WhatsApp from '@interco/icons/orangeds/MD/whatsapp'

export const Icons = {
  BookOpen,
  Canceled,
  Card,
  Credit,
  Deposit,
  Dollar,
  Edit,
  Help,
  Home,
  Handbag,
  Ticket,
  Tag,
  Profitability,
  NoTaxes,
  NotFound,
  ChevronRight,
  Consortium,
  Wallet,
  Check,
  CheckCircle,
  Attention,
  PdfFile,
  Receipt,
  Phone,
  PhoneCall,
  Pending,
  ComputerCall,
  WhatsApp,
  Email,
}
