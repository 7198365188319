import { SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface LoadSummaryListProps {
  qtd?: number
}

export const LoadSummaryList = ({ qtd }: LoadSummaryListProps) => {
  const renderListSummary: number[] = [...Array(qtd).keys()]

  return (
    <>
      {renderListSummary.map(() => (
        <SkeletonGrid key={uuid()}>
          <InterUISkeleton height="16px" width="50%" marginBottom="16px" />
          <InterUISkeleton height="16px" width="20%" marginBottom="16px" />
        </SkeletonGrid>
      ))}
    </>
  )
}
