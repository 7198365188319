import { Reducer } from 'redux'
import { AboutUrlEnum } from 'src/enums/AboutUrl'

import { UrlState, UrlTypes } from './types'

const INITIAL_STATE: UrlState = {
  selectedUrl: AboutUrlEnum.FAQ,
}

export const reducer: Reducer<UrlState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UrlTypes.FAQ:
      return { ...state, selectedUrl: AboutUrlEnum.FAQ }
    case UrlTypes.KNOW_MORE:
      return { ...state, selectedUrl: AboutUrlEnum.KNOW_MORE }
    default:
      return state
  }
}

export default reducer
