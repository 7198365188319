import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/PageTitles'
import { NavbarActions } from 'src/store/ducks/navbar/actions'

interface BasePage {
  navbarTitle: PageTitles
  hideFaqBtn?: boolean
  showHomeBtn?: boolean
  hideBackNavigationBtn?: boolean
  backNavigationHandler?: () => void
}

export const useBasePage = (data: BasePage) => {
  const dispatch = useDispatch()

  const { navbarTitle, hideFaqBtn, hideBackNavigationBtn, showHomeBtn, backNavigationHandler } =
    data

  useEffect(() => {
    dispatch(NavbarActions.setTitle(navbarTitle))

    if (hideFaqBtn) {
      dispatch(NavbarActions.hideFaqBtn())
    } else {
      dispatch(NavbarActions.showFaqBtn())
    }

    if (hideBackNavigationBtn) {
      dispatch(NavbarActions.hideBackNavigationBtn())
    } else {
      dispatch(NavbarActions.showBackNavigationBtn())
    }

    if (showHomeBtn) {
      dispatch(NavbarActions.showHomeBtn())
    } else {
      dispatch(NavbarActions.hideHomeBtn())
    }

    if (backNavigationHandler) {
      dispatch(NavbarActions.setBackNavigationHandler(backNavigationHandler))
      BaseBridge.addBackListener(backNavigationHandler)
    }
  }, [dispatch, navbarTitle])
}
