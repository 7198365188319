import React from 'react'

import { FallbackProps } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { ErrorActions } from 'src/store/ducks/error/actions'

import { ErrorPage } from './ErrorPage/ErrorPage'

export const FallbackError: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const errorDetails = useSelector(ErrorActions.getErrorDetails)

  const goBack = () => BaseBridge.requestGoBack()

  const page = (
    <ErrorPage
      title={errorDetails.title}
      subTitle={errorDetails.subTitle}
      goHome={goBack}
      done={resetErrorBoundary}
    />
  )

  return <>{page}</>
}
