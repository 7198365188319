import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ConsortiumProgress } from './ConsortiumProgress/ConsortiumProgress'
import { Information } from './Information/Information'
import { QuickAccessBar } from './QuickAccessBar/QuickAccessBar'
import { Quota } from './Quota/Quota'

export const MyConsortiaDetail = () => {
  const history = useHistory()

  const { consortiumProgress, quota, quickAcessBar, contact, paymentMethod, cancellation } =
    useSelector(AfterSalesActions.getConsortiumDetails)

  useBasePage({
    navbarTitle: PageTitles.MY_CONSORTIA,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
  })

  const stickyFooter = <Babi margin="0px" />

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <ConsortiumProgress consortiumProgress={consortiumProgress} />
      <Quota quotaInfo={quota} />
      <QuickAccessBar quickAcessBar={quickAcessBar} />
      <Information info={contact} paymentMethod={paymentMethod} cancelation={cancellation} />
    </InterUIContainer>
  )
}
