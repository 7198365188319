import styled from 'styled-components'

/**
 * Container em que deve ser exibido o pdf
 * 152px = 56px(header) + 96px(stickyFooter)
 */
export const PdfContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 152px);
`

export const LoadContainer = styled(PdfContainer)`
  height: calc(100vh - 56px);
`
