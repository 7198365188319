import { Fragment, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ICheckoutBS } from 'src/Interfaces/ICheckout'
import { getIcon } from 'src/common/icons/icons'
import { Button, PaymentMethod, SummaryList } from 'src/components'
import { ISummaryItem } from 'src/components/SummaryList/SummaryList'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { CheckoutEnum, LabelsBSEnum } from 'src/enums/Checkout'
import { PageTitles } from 'src/enums/PageTitles'
import { SummaryListVariantsEnum } from 'src/enums/SummaryListVariants'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { formPaymentEmpty } from 'src/mocks/Checkout'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { BtnLink, H1, H2, H3, InfoSign, PLarge, PMedium, PSmall, PXSmall } from 'src/styles/commons'
import { formatDayMonthYear } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import {
  InterUIBottomSheet,
  InterUIBox,
  InterUICheckBox,
  InterUIContainer,
  InterUIRadio,
  InterUISeparator,
  InterUISwitch,
} from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'

import BridgeService from 'src/services/bridgeService'
import { ICard } from 'src/store/ducks/hiring/types'
import { CheckoutBS } from './CheckoutBS/CheckoutBS'

export const Checkout = (card: ICard) => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const selectedPlan = useSelector(HiringActions.getPlan)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const checkedConsortiumInsurance = useSelector(HiringActions.getCheckedConsortiumInsurance)
  const { plan, insurance, bottomSheet, ...checkout } = useSelector(HiringActions.getCheckout)

  const [checkoutBS, setCheckoutBS] = useState(false)
  const [aboutPLansBS, setAboutPLansBS] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [choseLabel, setChoseLabel] = useState<ICheckoutBS>(LabelsBSEnum.RESERVE)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalWithInsurance, setTotalWithInsurance] = useState(0)

  useBasePage({
    navbarTitle: PageTitles.CONSORTIUM,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_CONSOR_RESUMO_DC_ACESSO.screen, {
      ...TAGS.C_CONSOR_RESUMO_DC_ACESSO,
      'Valor da carta': `${checkout.consortiumValue}`,
      'Valor da Parcela': `${selectedPlan.totalAmount}`,
      Modalidade: `${checkout.category}`,
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
    BridgeService.tacWb({
      name: 'receive_detail',
      module: 'consortium',
      actions: ['click_detail'],
      params: {
        isVip: card.isVip,
        productId: card.productId,
        cardId: card.cardId,
        cardTitle: card.cardTitle,
        offer: card.offer,
        consortiumValue: card.consortiumValue,
        installmentValue: card.installmentValue,
        term: card.term,
        group: card.group,
        highlightCard: card.highlightCard,
      },
    })
  }, [])

  useEffect(() => {
    if (insurance) {
      checkedConsortiumInsurance
        ? setTotalAmount(selectedPlan.totalAmount + insurance.amount)
        : setTotalAmount(selectedPlan.totalAmount)

      setTotalWithInsurance(selectedPlan.totalAmount + insurance.amount)
    } else {
      setTotalAmount(selectedPlan.totalAmount)
    }
  }, [])

  const summaryList: ISummaryItem[] = [
    {
      id: 0,
      text: 'Número de parcelas',
      value: `${checkout.installmentNumber}`,
    },
    {
      id: 1,
      text: 'Data de cobrança',
      value: `${checkout.billingDate}`,
    },
    {
      id: 2,
      text: 'Taxa de fundo de reserva',
      value: `${checkout.reserveFundFee}%`,
      icon: LabelsBSEnum.RESERVE,
    },
    {
      id: 3,
      text: 'Taxa de administração',
      value: `${checkout.administrationFee}%`,
      icon: LabelsBSEnum.ADMIN,
    },
    {
      id: 4,
      text: 'Grupo',
      value: `${checkout.group}`,
      icon: LabelsBSEnum.GROUP,
    },
  ]

  const resetFormOfPayment = () => {
    dispatch(
      CheckoutActions.setFormOfPayment({
        title: formPaymentEmpty.title,
        subTitle: formPaymentEmpty.subTitle,
        type: formPaymentEmpty.type,
      }),
    )
  }

  const handleSelectedPlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormOfPayment()

    const { value } = event.target

    const findPlan = plan.plans.find((item) => item.salesPlanId.toString() === value)

    if (findPlan) {
      dispatch(
        HiringActions.setPlan({
          salesPlanId: findPlan.salesPlanId,
          salesType: findPlan.salesType,
          plan: findPlan.title,
          totalAmount: findPlan.totalAmount,
          offer: findPlan.offer,
        }),
      )

      if (insurance) {
        checkedConsortiumInsurance
          ? setTotalAmount(findPlan.totalAmount + insurance.amount)
          : setTotalAmount(findPlan.totalAmount)

        setTotalWithInsurance(findPlan.totalAmount + insurance.amount)
      } else {
        setTotalAmount(findPlan.totalAmount)
      }
    }
  }

  const handleClickMembershipProposal = () => {
    dispatch(
      HiringActions.getProposalRequest({
        history,
        pathname: TypesRoutes.PROPOSAL,
        consortiumId: `${checkout.proposalUuid}`,
        installmentValue: totalAmount.toFixed(2),
        insurance: `${checkedConsortiumInsurance}`,
        planId: `${selectedPlan.salesPlanId}`,
        typeSaleGroup: `${selectedPlan.salesType}`,
      }),
    )
  }

  const handleOpenBS = (item: ICheckoutBS) => {
    setChoseLabel(item)
    setCheckoutBS(true)
  }

  const handleClickContract = async () => {
    if (BaseBridge.isBrowser()) {
      dispatch(
        HiringActions.getReceiptRequest({
          proposalUuid: checkout.proposalUuid,
        }),
      )
      history.push(TypesRoutes.RECEIPT)
    } else {
      try {
        BaseBridge.requestAnalytics(TAGS.CONSOR_FLUXO.screen, {
          ...TAGS.CONSOR_FLUXO,
          utm_campaign: utmCampaign || 'SEM_CAMPANHA',
          account_context,
          segment,
          ref_figma: '7',
          action_id: 'Contratar',
          Modalidade: `${checkout.category}`,
          Oferta: `${selectedPlan.offer}`,
          'Valor da carta': `${checkout.consortiumValue}`,
          'Valor da Parcela': `${selectedPlan.totalAmount}`,
          Plano: `${selectedPlan.plan}`,
          'Payment method': `${formOfPayment.type}`,
          seguro: `${checkedConsortiumInsurance}`,
          'Valor do Seguro': `${insurance?.amount}`,
        })

        const result = await CheckoutBaseService.contractWallet(`${totalAmount}`)

        resetFormOfPayment()

        if (result.status === 'SUCCESS') {
          dispatch(
            HiringActions.getReceiptRequest({
              proposalUuid: checkout.proposalUuid,
            }),
          )
          history.push(TypesRoutes.RECEIPT)
        }
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'Subscription.finalizeContract',
        })

        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const renderSelectPlan = (
    <>
      {plan && (
        <PLarge marginBottom="16px" bold>
          {plan.title}
        </PLarge>
      )}

      {plan &&
        plan.plans.map((item) => (
          <InterUIRadio
            key={uuid()}
            id={`plan-${uuid()}`}
            data-testid={`plan-${item.salesPlanId}`}
            variant="choose-item"
            radioPosition="right"
            margin="0 0 16px"
            name={item.title}
            value={item.salesPlanId}
            checked={selectedPlan.salesPlanId === item.salesPlanId}
            onChange={handleSelectedPlan}
          >
            <PMedium bold>{item.title}</PMedium>
            {item.subtitle && <PSmall scale={400}>{item.subtitle}</PSmall>}

            <PXSmall marginBottom="4px">
              Em até <strong>{item.installments} meses</strong>
            </PXSmall>

            {item.firstInstallments && (
              <PMedium scale={400} marginBottom="4px">
                {item.firstInstallments}
              </PMedium>
            )}

            <PMedium scale={400} marginBottom="0">
              {item.amount}
            </PMedium>
          </InterUIRadio>
        ))}
    </>
  )

  const toggleInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormOfPayment()

    const { checked } = event.target
    dispatch(HiringActions.setCheckedConsortiumInsurance(checked))

    !checkedConsortiumInsurance && insurance !== undefined
      ? setTotalAmount(selectedPlan.totalAmount + insurance.amount)
      : setTotalAmount(selectedPlan.totalAmount)
  }

  const renderInsurance = (
    <>
      {insurance && (
        <InterUIBox direction="column" margin="0 0 24px" actived={checkedConsortiumInsurance}>
          <InterUISwitch
            data-testid="test-switch"
            margin="0 0 10px"
            value="accept-insurance"
            checked={checkedConsortiumInsurance}
            onChange={toggleInsurance}
            style={{ width: '100%' }}
          >
            <PMedium marginBottom="4px" bold>
              {insurance.title}
            </PMedium>
            <PSmall marginBottom="4px" scale={400} bold>
              {`${formatToBRL(insurance.amount)} por mês`}
            </PSmall>

            <PXSmall marginBottom="0" scale={400}>
              Sua parcela com seguro {formatToBRL(totalWithInsurance)}
            </PXSmall>
          </InterUISwitch>

          <PSmall marginBottom="0" scale={400}>
            {insurance.subtitle}
          </PSmall>
        </InterUIBox>
      )}
    </>
  )

  const renderBSPlans = (
    <>
      {bottomSheet && (
        <InterUIBottomSheet
          data-testid="about-bottom-sheet"
          toggle={aboutPLansBS}
          onHide={() => setAboutPLansBS(false)}
        >
          {bottomSheet.map((item, index) => (
            <Fragment key={uuid()}>
              <PLarge bold>{item.title}</PLarge>
              <PMedium scale={400} marginBottom="24px">
                {item.description}
              </PMedium>
              {index !== bottomSheet.length - 1 && (
                <InterUISeparator height="1px" margin="0 0 24px" />
              )}
            </Fragment>
          ))}

          <Button variant="primary" onClick={() => setAboutPLansBS(false)}>
            Entendi
          </Button>
        </InterUIBottomSheet>
      )}
    </>
  )

  const stickyFooter = (
    <>
      <InterUISeparator height="1px" />

      <InterUIContainer margin="0 24px 24px">
        <InterUICheckBox
          value=""
          name="no-number"
          id="no-number"
          data-testid="test-checkbox"
          onChange={() => setAcceptTerms(!acceptTerms)}
          checked={acceptTerms}
        >
          <PSmall marginBottom="0">
            Li e concordo com as informações descritas no documento de
            <BtnLink margin="0 0 0 4px" small onClick={handleClickMembershipProposal}>
              Proposta de adesão.
            </BtnLink>
          </PSmall>
        </InterUICheckBox>

        <Button
          data-testid="button-continue"
          disabled={!acceptTerms || !formOfPayment.type}
          onClick={() => handleClickContract()}
        >
          Contratar
        </Button>
      </InterUIContainer>
    </>
  )

  return (
    <>
      {Object.keys(checkout).length !== 0 && (
        <InterUIContainer margin="0px" stickyFooter={stickyFooter}>
          <InterUIContainer margin="24px 24px 32px">
            <InfoSign>
              {getIcon(checkout.icon, {
                color: theme.colors.primary.A500,
                width: 32,
                height: 32,
              })}
            </InfoSign>
            <PMedium margin="8px 0 16px" scale={400} textAlign="center">
              {checkout.category}
            </PMedium>
            <H1 marginBottom="24px" textAlign="center">
              {formatToBRL(checkout.consortiumValue)}
            </H1>
            <H3 marginBottom="16px">Detalhes</H3>

            <SummaryList
              items={summaryList}
              variant={SummaryListVariantsEnum.PS}
              handleClick={(item) => handleOpenBS(item as ICheckoutBS)}
            />

            <InterUISeparator variant="small" margin="24px 0" />

            {renderSelectPlan}

            {bottomSheet && bottomSheet.length > 0 && (
              <BtnLink onClick={() => setAboutPLansBS(true)}>Entenda mais sobre os planos</BtnLink>
            )}
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="24px 24px 16px">
            {renderInsurance}

            <H2 marginBottom="8px">Como você quer pagar?</H2>
            <PMedium marginBottom="24px" scale={400}>
              Primeira cobrança em <strong>{formatDayMonthYear(new Date())}</strong>.
            </PMedium>

            <PaymentMethod
              type={CheckoutEnum.HIRE}
              amount={totalAmount}
              proposalUuid={checkout.proposalUuid}
              salesPlanId={selectedPlan.salesPlanId}
              salesType={selectedPlan.salesType}
              inInsurance={checkedConsortiumInsurance}
            />
          </InterUIContainer>

          {renderBSPlans}

          <CheckoutBS choseLabel={choseLabel} toggleState={[checkoutBS, setCheckoutBS]} />
        </InterUIContainer>
      )}
    </>
  )
}
